.SearchItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 5px 6px 15px;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #5fc56e;
  border-radius: 100px;
  transition: 1s;
  overflow: hidden;
  height: 35px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
  }

  &__Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 22px;
    background: #5fc56d;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
  }

  img {
    height: 26px;
    margin-right: 4px;
  }
}