.ImageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 52;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__close {
    position: absolute;
    bottom: 100px;
    font-size: 24px;
    background-color: #5fc56e;
    padding: 10px 20px;
    border-radius: 32px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
  }
}