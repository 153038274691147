.Preview { 
    width: 100vw;
    // margin-left: -20px;
    background: #E1E1E0;
    padding-bottom: 40px;

    &List {
        display: flex;
        flex-direction: column;

        width: 80%;
        margin: auto;
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 3px;
        margin-bottom: 40px;

        &__title {
            margin-top: 60px;
            margin-bottom: 40px;

            color: #000;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 300;
            display: flex;
            align-items: center;

            img {
                margin-right: 20px;
            }
        }

        &__title:hover{
            cursor: pointer;
            font-weight: 600;
        }

        &__categories {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            max-width: 80vw;
            margin: auto;
            flex-wrap: nowrap;
            overflow-x: scroll;

            &__item {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                cursor: pointer;

                color: #000;
                font-size: 32px;
                font-weight: 300;
                text-wrap: wrap;
                text-align: center;
                width: 220px;
                height: 300px;
                margin: 0px 15px;

                img {
                    border-radius: 30px;
                    width: 220px;
                    height: 220px;
                }
            }
        }
        &__brands {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1530px;
            margin: auto;
            align-items: center;
            flex-wrap: nowrap;
            overflow-x: scroll;
            
            &__item {
                margin-right: 30px;
                margin-top: 30px;
                overflow: hidden;
                border-radius: 30px;
                width: 220px;
                height: 220px;
                margin: 0px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                
                img {
                    border-radius: 30px;
                    width: 100%;
                }
            }
        }
        &__businessType {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            max-width: 1530px;
            flex-wrap: nowrap;
            overflow-x: scroll;

            &__item {
                margin: 0px 15px;
                cursor: pointer; 
                img {
                    border-radius: 30px;
                    width: 220px;
                    height: 220px;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .PreviewList {
        width: 90%;

        &__categories {
            &__item {
                width: 180px;
                height: 260px;
                text-align: center;
                img {
                    border-radius: 30px;
                    width: 180px;
                    height: 180px;
                }
            }
        }
        &__brands {
            padding-bottom: 30px;
            &__item {
                width: 180px;
                height: 180px;
                text-align: center;
                overflow: hidden;
                img {
                    border-radius: 30px;
                    width: 180px;
                    height: 180px;
                    object-fit: contain;
                }
            }
        }
        &__businessType {
            &__item {
                width: 180px;
                height: 180px;
                text-align: center;
                overflow: hidden;
                img {
                    border-radius: 30px;
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .PreviewList {
        overflow: auto;
        width: 90%;
        
        &__title {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        &__categories {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            max-width: 80vw;
            margin: auto;
            flex-wrap: nowrap;
            overflow-x: scroll;
            &__item {
                width: 70px;
                height: 92px;

                text-align: center; color: black; font-size: 12px; font-family: Montserrat; font-weight: 400;
                img {
                    border-radius: 13px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
        &__brands {
            max-width: 1530px;
            justify-content: center;

            &__item {
                width: 70px;
                height: 70px;

                text-align: center; color: black; font-size: 12px; font-family: Montserrat; font-weight: 400;
                border-radius: 13px;
                margin: 0px 15px;
                img {
                    border-radius: 5px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
        &__businessType {
            &__item {
                width: 70px;
                height: 70px;

                text-align: center; color: black; font-size: 12px; font-family: Montserrat; font-weight: 400;
                img {
                    border-radius: 13px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
    
}