.SearchListItemCounter {
  width: 100%;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    gap: 10px;

    &__price {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding: 0 10px;
      width: 100%;
      height: 30px;
      background: linear-gradient(90deg, #9be15d 0%, #00e3ae 90%);

      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.12em;
      text-align: center;
      color: #fff;
      transition: all 1s;
    }
    &__addBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding: 0 10px;
      width: 100%;
      height: 30px;
      background: #5fc56e;

      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.12em;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
    &__removeBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      min-width: 30px;
      height: 30px;
      background: #5fc56e;
      font-weight: 400;
      font-size: 32px;
      letter-spacing: 0.06em;
      text-align: center;
      color: #fff;
      cursor: pointer;
      transform: rotate(45deg);
    }
  }
}