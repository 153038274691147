.staticDashboard {
  font-family: 'Montserrat';
  width: 100%;
  margin: 0 auto;

  display: flex;

  @media screen and (max-width: 798px) {
    flex-direction: column;
  }
}