.saleBtn {
  width: fit-content;
  height: 60px;
  background: #5fc56e;
  border-radius: 24px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  outline: none;
  border: none;
  transition: 0.5s;
  text-wrap: no-wrap;

  font-weight: 400;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #fff;
  font-family: "Archivo Black", sans-serif;
}