

.previewNav {
    display: flex;
    margin-top: 40px;
    margin-bottom: 34px;
    justify-content: center;
    // align-items: center;

    &__button {
        margin: 2px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 1px 17px;
        border-radius: 100px;
        background: #FFF;
        cursor: pointer;

        color: #5FC56E;

        font-family: 'Archivo Black';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        border-bottom: 3px solid #FFF;
        transition: border-color .9s;

        img {
            margin-left: 10px;
            height: 26px;
        }

        &:hover {
            border-bottom: 3px solid #5FC56E;
        }
    }
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 96px;

        img {
            width: 50px;
            margin: 0 10px;
            transition: 1s;
            &:hover {
                filter: brightness(0.9);
                filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
                cursor: pointer;
            }
        }

        &__modal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 32px;
            background: #FFF;
            padding: 10px 30px;
            width: 350px;
            transition: opacity 1s;


            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }

                img {
                    width: 47px;
                    height: 47px;
                    padding: 7px;
                    border-radius: 8px;
                    border: 1px solid #5FC56D;
                }

                span {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: 3px;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .previewNav {
        &__menu {
            right: 0;
        }
    }
}
@media screen and (max-width: 1400px) {
    .previewNav {
        display: none;
    }
    
}