.Profile__mainPage__section__mainSection__item__ordersSection__content__item {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;

	&__left {
		width: 70%;
				
		&__header {
			display: flex;
			flex-direction: row;

			img {
					width: 50px;
					height: 50px;
					margin-right: 5px;
			}
			&__orderData {
					display: flex;
					flex-direction: column;
					color: #4C4C4C;
					font-family: Montserrat;

					&__number {
							font-size: 12px;
							font-weight: 400;
							letter-spacing: 1.2px;
					}
					&__status {
							font-size: 18px;
							font-weight: 500;
							letter-spacing: 1.8px;
							padding: 2px 0;
					}
					&__item {
							font-size: 12px;
							font-weight: 400;
							letter-spacing: 1.2px;
					}
			}
		}
		&__btnContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 83%;
			margin-top: 10px;

			&__btn {
					width: 164px;
					height: 40px;
					border-radius: 10px;
					background: #E8E8E8;
					display: flex;
					justify-content: center;
					align-items: center;

					color: #4C4C4C;
					font-family: Montserrat;
					font-size: 16px;
					font-weight: 400;
					letter-spacing: 1.6px;
			}
		}
	}
	&__right {
			color: #5FC56D;
			font-family: Roboto;
			font-size: 24px;
			font-weight: 400;
	}
}

.Snackbar {
	display: flex;
	justify-content: center;
	align-items: center;
	* {
		border-radius: 32px !important;
		font-family: Montserrat, sans-serif;
		color: #5FC56D;
	}
}