.contentCard {
  cursor: pointer;
  width: 300px;
  max-height: 560px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 25px;

  background-color: #fff;

  border-radius: 32px;

  &__head {
    height: 35px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    position: relative;
    &__spo {
      position: absolute;
      left: -15px;
      top: -20px;

      width: 60px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      border-top-left-radius: 10px;

      background-color: #ede870;

      color: #4c4c4c;

      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.4px;
    }

    &__heart {
      position: absolute;
      right: 0;
      top: 0;
    }
    span {
      margin-right: 5px;
    }
    span:hover{
      opacity: 0.5;
    }

  }

  &__body {
    width: 100%;
    height: 72%;
    display: flex;
    flex-direction: column;
    // justify-content: center;

    &__title {
      color: #4c4c4c;
      align-self: left;

      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1.6px;
      margin-bottom: 1px;
      max-height: 55px;
    }

    &__subtitle {
      display: flex;
      align-self: flex-flex-start;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 5px;
      gap: 5px;


      &__info {
        border-radius: 10px;
        background-color: #f5f4f2;
        padding: 2px;

        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.4px;

        &__svg {
          margin-right: 5px;
        }
      }
    }

    &__img {
      height: fit-content;
      overflow: hidden;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      // margin-bottom: 12px;
      img {
        height: 150px;
        // width: 100%;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    &__price {
      margin-top: 10px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.07em;
      color: #4c4c4c;
      
      span {
        color: #4C4C4C;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 20px;
      }
    }

    &__addBtn {
      width: 55px;
      height: 55px;

      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.stock {
  padding: 5px 10px;
  border-radius: 32px;
  color: #5fc56e;
  border: 1px solid #5fc56e;
  margin-right: 10px;
  img {
    margin-right: 2px;
  }
}

.dias {
  padding: 5px 10px;
  border-radius: 32px;
  color: #407bff;
  border: 1px solid #407bff;
  margin-right: 10px;
  width: fit-content !important;
}

.outOfStock {
  padding: 5px 10px;
  border-radius: 32px;
  color: #ff725e;
  border: 1px solid #ff725e;
  margin-right: 10px;
}

.TBC {
  padding: 5px 10px;
  border-radius: 32px;
  color: #ffc727;
  border: 1px solid #ffc727;
  margin-right: 10px;
  width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .contentCard {
    width: 160px;
    max-height: 380px;
    border-radius: 20px;
    box-sizing: border-box;
    min-width: none !important;
    padding: 15px;
    .counterMargin {
      width: 100%;
      margin: 0;
      .CounterModal {
        min-width: fit-content !important;    
        &__select {
          width: fit-content !important;
          flex-direction: column;
          height: fit-content;
          border-radius: 20px;
          &__controller {
            height: 40px;
            margin-top: -1px;
          }
          &__unit {
            &__options {
              top: 80px;
              width: 100%;
            }
          }
        }
      }
    }
    &__head {
      margin-top: -10px;
      .contentCard__body__subtitle__info {
        align-self: flex-flex-start;
        margin-top: 4px;
      }
      &__heart {
        width: 20px;
      }
    }
    &__body {
      &__img {
        margin: 0 auto;
        margin-left: -10px;
        // width: 130px;
        height: 140px;
        img {
          margin: 0 auto;
          height: 100%;
        }
      }
      &__title {
        font-size: 12px;
        margin-bottom: 0px;
        max-height: 50px;
        overflow-y: scroll;
      }
      &__subtitle {
        margin-bottom: 5px;
        margin-top: 10px;
        &__info {
          margin-top: 0;
          font-size: 10px !important;
          width: fit-content;
          height: 15px;
          box-sizing: border-box;
          padding: 0 4px !important;
          text-wrap: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          &__svg {
            width: 10px;
          }
        }
      }
    }
    &__footer {
      height: 23px;
      &__price {
        margin-top: 0;
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 500;
        letter-spacing: 1.60px;
        span {
          font-size: 10px;
          font-weight: 500; 
          letter-spacing: 1.60px;
        }
      }
      &__addBtn {
        width: 23px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contentCard {
    .counterMargin {
      width: 100%;
      margin: 0;
      .CounterModal {
        min-width: fit-content !important;    
        &__select {
          width: 100% !important;
         
          &__controller {
            width: 100%;
            height: 30px;
          }
          &__unit {
            height: 20px;
            &__options {
              top: 60px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}