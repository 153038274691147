.OrderPage {
    background: #E1E1E0;
    box-sizing: border-box;
    width: 100vw;
    margin-left: -20px;
    padding: 75px 0;
    box-sizing: border-box;

    &__back {
        display: none;
        margin-left: 40px;
        margin-bottom: 10px;
        img {
            width: 60px;
        }
    }

    &__total {
        margin: auto;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__sideBar {
            margin-right: 25px;
            width: fit-content;
        }
        &__content {
            display: flex;
            justify-content: space-between;
            width: 1065px;
            
            &__info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 25px;

                &__head {
                   box-sizing: border-box;
                   width: 290px;
                   height: 227px;
                   padding: 25px; 
                   display: flex;
                   flex-direction: column; justify-content: flex-flex-start; 
                   align-items: flex-flex-start; 
                   background: white; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 32px;
                   margin-bottom: 32px;

                   color: #4C4C4C; font-size: 24px; font-family: Montserrat; font-weight: 600;

                   p {
                    margin: 0;
                    span {
                        color: black; font-weight: 400;
                    }
                   }
                   span {
                    font-weight: 400;
                   }
                   &__block {
                    margin-bottom: 20px;
                   }
                }
                &__body {
                    background: white; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 32px;
                    box-sizing: border-box;
                    padding: 25px;
                    width: 290px;
                    height: 227px;
                    
                    color: #4C4C4C; font-size: 24px; font-family: Montserrat; font-weight: 600; 

                    ::-webkit-scrollbar {
                        height: 10px;
                        width: 15px;
                    }
                    ::-webkit-scrollbar-track {
                        border-radius: 5px;
                        background-color: #DBDBDB;
                    }
                    ::-webkit-scrollbar-thumb {
                        border: 2px solid #DBDBDB;
                    }

                    &__comment {
                    margin-top: 25px;
                    color: black;
                    font-size: 24px; 
                    font-family: Montserrat; 
                    font-weight: 400;
                    overflow-x: hidden;
                    width: 239px;
                    height: 128px;
                    background: #DBDBDB; border-radius: 10px;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    box-shadow: -20px 0px 0px white inset
                    }
                }
            }
            &__totalPrice {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__total {
                    width: 600px;
                    height: 394px;
                    background: white;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
                    border-radius: 32px;
                    box-sizing: border-box;
                    padding: 30px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &__prices {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        div:last-child {
                            font-weight: 700;   
                            span {
                                color: #5FC56D;    
                            } 
                        }

                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 30px;

                            color: black;
                            font-size: 32px;
                            font-family: Montserrat;
                            font-weight: 500;
                            p {
                                margin: 0;
                            }
                        }
                    }
                    &__paid {
                        margin-top: 40px;
                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 30px;

                            color: black;
                            font-size: 32px;
                            font-family: Montserrat;
                            font-weight: 500;
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
                &__btnBox {
                    margin-top: 50px;
                    display: flex;
                    width: 600px;
                    justify-content: space-between;
                    align-items: center;

                    &__btn {
                        width: 140px;
                        height: 44px;
                        background: #5FC56E;    
                    }
                }
            }
        }
    }
    &__products {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;

        &__btnBox {
            display: flex;
            justify-content: center;
            align-items: center;

            :last-child {
                background: #5FC56D;
            }

            &__btn {
                width: 140px;
                height: 44px;
                background: #fff;
                margin: 0 15px;
            }
        }
        &__table {
            width: 80%;
            margin: auto;
            display: flex;
            flex-direction: column;

            &__head {
                display: flex;
                justify-content: center;
                margin-top: 40px;
                width: 100%;
                height: 90px;
                box-sizing: border-box;
                padding: 20px 40px;
                background: #5FC56D;
                border-radius: 10px;

                color: black;
                font-size: 24px;
                font-family: Montserrat;
                font-weight: 400;
                
                table {
                    width: 100%;
                    
                    tr {
                        margin-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;
                    }
                }
            }
            &__body {
                margin-top: 20px;
                box-sizing: border-box;
                padding: 10px 0;
                background: #fff;

                table {
                    width: 100%;

                    tr {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        td {
                            color: black;
                            font-size: 24px;
                            font-family: Montserrat;
                            font-weight: 400;
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            align-items: center;
                            

                            span {
                                color: rgba(0, 0, 0, 0.40);
                            } 
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .OrderPage {
        &__total {
            width: 90%;
        }
        &__products {
            &__table {
                width: 90%;
            }
        }
    }
    
}

@media screen and (max-width: 1400px) {
    .OrderPage {
        &__back {
            display: block;
        }
        &__total {
            width: 75%;
            &__sideBar {
                display: none;
            }
        }
        &__products {
            &__table {
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .OrderPage {
        &__total {
            width: 96%;
        }
        &__products {
            &__table {
                &__body {
                    table {
                        tr {
                            td {
                                font-size: 16px!important;
                                span {
                                    font-size: 14px!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .OrderPage {
        &__total {
            width: 90%;
            margin: auto;
            &__content {
                flex-direction: column;
                align-items: center;
                &__info {
                    flex-direction: row;
                    align-items: flex-start;
                    margin-right: 0px;
                    &__head {
                        background: none;
                        box-shadow: none;
                    }
                    &__body {
                        margin-right: 0px;
                        width: fit-content;
                    }
                }
                &__totalPrice {
                    width: 100%;
                    &__total {
                        width: 100%;
                    }
                }
            }
        }
        &__products {
            &__btnBox {
                &__btn {
                    width: 100px;
                    height: 30px;
                }
            }
            &__table {
                &__head {
                    table {
                        tr {
                            td {
                                font-size: 16px!important;
                            }
                        }
                    }
                }
                &__body {
                    table {
                        tr {
                            td {
                                :nth-child(2) {
                                    font-size: 14px!important;
                                }
                                img {
                                    width: 50px;
                                }
                                font-size: 16px!important;
                                span {
                                    font-size: 14px!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .OrderPage {
        &__total {
            width: 90%;
            margin: auto;
            &__content {
                width: 100%;
                margin: 0;
                &__info {
                    &__head {
                        padding: 10px;
                        width: fit-content;
                    }
                }
            }
        }
        &__products {
            &__table {
                &__head {
                }
                &__body {
                    table {
                        tr {
                            td {
                                :nth-child(2) {
                                    font-size: 14px!important;
                                }
                                img {
                                    width: 50px;
                                }
                                font-size: 16px!important;
                                span {
                                    font-size: 14px!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 565px) {
    .OrderPage {
        &__total {
            &__content {
                &__info {
                    &__head {
                        p {
                            font-size: 16px !important;
                        }
                        span {
                            font-size: 16px !important;
                        }
                    }
                    &__body {
                        padding: 15px;
                        height: fit-content;
                        &__head {
                            font-size: 16px !important;
                        }
                        &__comment {
                            width: 180px;
                            margin-top: 10px;
                            font-size: 16px !important;
                            padding: 10px 15px;
                        }
                    }
                }
                &__totalPrice {
                    &__total {
                        height: fit-content;
                        div {
                            font-size: 18px !important;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        &__products {
            &__table {
                &__head {
                    table {
                        tr {
                            td {
                                font-size: 14px!important;
                            }
                        }
                    }
                }
            }
        }
    }
}