footer{
  bottom: 0px;
  margin: auto;
  background: #F5F4F2;
  width: 100%;

  height: 150px;

  font-family: Montserrat;
  .footer__main{
    width: 100%;
    height: 100%;
  }
  .footer__white__fake {
    // background: white;
    // border: 1px solid #e8e8e8;
    border-top: none;
  }
  .footer__about{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .footer__about__btn{
      color: #4C4C4C;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 1.6px;
    }
  }
}

@media screen and (max-width: 768px) {
  footer{
    height: 50px;
  }
  .footer__main {
    .footer__about__btn {
      color: black !important;
      font-size: 10px !important;
      font-family: Montserrat;
      font-weight: 500 !important;
      letter-spacing: 1.60px !important;
    }
  }
}

@media screen and (max-width: 590px) {
  footer{
    height: 50px;
    // position: fixed;
    bottom: 0px;
    .footer__main {
      .footer__about__btn {
        color: black !important;
        font-size: 8px !important;
        font-family: Montserrat;
        font-weight: 500 !important;
        letter-spacing: 1.60px !important;
      }
    }
  }
}