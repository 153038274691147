.SaleMyOrders {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
  color: #252525;

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .SaleContainer {
    background: #fff;
    border-radius: 32px;
    box-shadow: 6px 7px 17px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    p {
      font-weight: 500;
      font-size: 24px;
      color: #585858;
    }
  }

  &__head {
    display: flex;
    height: 255px;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    gap: 20px;

    &Data {
      display: flex;
      flex-direction: column;
      height: 255px;
      align-items: flex-start;
      align-content: space-between;
      justify-content: space-between;
      gap: 20px;
      padding: 30px 45px;
  
      &__info {
        display: flex;
        height: 255px;
        width: 100%;
        gap: 5px;
        flex-direction: column;
        justify-content: space-between;

        h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 40px;
        }
        p {
          font-weight: 500;
          font-size: 24px;
        }

        &__active {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
        }
      }

      &__timer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        width: 414px;
        height: fit-content;

        p {
          font-weight: 700;
          font-size: 24px;
          letter-spacing: -0.01em;
          color: #b662aa;
          text-wrap: nowrap;
          
        }
        div {
          font-weight: 700;
          font-size: 148px;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;

          line-height: 123%;
          letter-spacing: 0em;
          color: #b662aa;
        }
      }
    }
  }

  &__totalBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;  
    color: #252525;
    font-size: 32px;
    height: 75px;
    gap: 10px;
    padding: 10px 40px;
    width: 100%;
    border: 1px solid #b662aa

    span {
      color: #d9d9d9;
      margin: 0 10px;
    }

    div {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 32px;
        margin-right: 35px;
      }
      p {
        font-weight: 500;
        font-size: 32px;
        text-decoration: line-through;
        color: #a9a9a9;
        span {
          text-decoration-line: none;
        }
      }
      .span {
        font-weight: 700;
        font-size: 40px;
        color: #5fc56e;
        text-decoration: none;
      }
    }
    .totalTitle {
      h1 {
        font-family: "Archivo Black", sans-serif;
        font-weight: 400;
      }
    }
  }
  &__myOrders {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
  }
}

@media screen and (max-width: 1080px) {
  .SaleMyOrders {
    &__head {
      flex-direction: column;
      height: fit-content;
      &Data__timer {
        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    &__myOrders {
      .OrderElement {
        &__header {
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .SaleContainer {
    border-radius: 24px;
  }
  .SaleMyOrders {
    width: 380px;
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
    &__head {
      flex-direction: column;
      height: fit-content;
      &Data {
        box-sizing: border-box !important;
        padding: 15px 10px;
        text-align: center;
        h1 {
          font-size: 32px !important;
          width: 100%;
        }
        p {
          font-size: 16px !important;
          width: 100%;
        }
        height: fit-content;
        &__info__active {
          flex-direction: column;
          width: 100%;
        }
        &__timer {
          gap: 10px;
          p {
            font-size: 20px !important;
          }
          div {
            font-size: 128px;
            height: 90px;
          }
        }
      }
    }
    &__totalBar {
      padding: 5px;
      div {
        h1 {
          font-size: 20px;
          margin-right: 3px;
        }
        p {
          display: none;
        }
        .span {
          font-size: 24px;
        }
      }
      gap: 0;
      span {
        margin: 0;
      }
    }
    &__myOrders {
      .OrderElement {
        padding: 15px 10px;
        &__header {
          flex-direction: column;
          &__info {
            font-size: 16px;
          }
          &__stats {
            gap: 3px;
            &__info {
              div {
                h1 {
                  font-size: 16px;
                }
                p {
                  font-size: 20px;
                }
                span {
                  font-size: 24px;
                }
              }
              span {
                margin: 0;
              }
            }
            &__closeBtn {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .ItemOrderElement {
          flex-direction: column;
          gap: 0;
          &__info__img {
            img {
              width: 60px;
            }
          }
          &__stats {
            &__quantity {
              font-size: 32px;
              height: 55px;
            }
            &__totalPrice {
              font-size: 32px;
              span {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}