.ProfilePlate {
    display: flex;
    padding: 5px 0;
    font-family: Montserrat;

    &__img {
        color: rgba(0, 0, 0, 0.50);
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.167px;
        text-decoration-line: underline;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        animation: showModal;
        animation-delay: 0.0001s;
        animation-duration: 1s;

        span {
            margin-bottom: 150px;
            margin-left: -50px;
            img {
                z-index: 2;
                border: none;
                width: 60px;
                height: 60px;
            }
        }
        img {
            width: 190px;
            height: 190px;
            border-radius: 50%;
            border: 3px solid #5FC56D;
            object-fit: cover;
        }
    }
    
    &__name {
        margin-top: 5px;
        color: #000;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: -0.167px;  
    }
    &__username {
        margin-top: 25px;
        color: #000;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -0.167px;
        text-wrap: wrap;

        span {
            font-weight: 400;
        }
    }
    &__ambassador {
        margin-top: 25px;
        border-radius: 100px;
        background: #5FC56E;
        display: flex;
        width: fit-content;
        padding: 10px;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -0.167px;
    }
    &__id {
        margin-top: 25px;
        color: #000;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.167px;
        span {
            font-weight: 400;
        }
    }
    &__bonus {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-left: -60px;
        margin-bottom: 8px;

        img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
        }
    }
    &__letters {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        font-family: Montserrat;
        font-size: 24px;
        img {
            margin-top: -8px;
        }
        div {
            margin-top: -6px;
        }
    }   
}


@media screen and (max-width: 992px) {
    .ProfilePlate {
        &__letters {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .ProfilePlate {
        &__img {
            img {
                width: 150px;
                height: 150px;
            }
            span {
                margin-bottom: 130px;
                img {
                    width: 40px;
                    height: 40px;    
                }
            }
        }
        &__name {
            font-size: 18px;
        }
        &__username {
            font-size: 16px;
        }
        &__ambassador {
            margin-top: 15px;
            font-size: 16px;
            padding: 5px;
        }
        &__id {
            font-size: 18px;
        }
        &__bonus {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}
@media screen and (max-width: 576px) {
    .ProfilePlate {
        &__img {
            margin-right: 10px;
            img {
                width: 95px;
                height: 95px;
            }
            span {
                margin-bottom: 80px;
                margin-left: -30px;
                img {
                    width: 22px;
                    height: 22px;    
                }
            }
        }
        &__name {
            font-size: 12px;
            margin-top: 0;
        }
        &__username {
            font-size: 12px;
            margin-top: 10px;
        }
        &__ambassador {
            margin-top: 10px;
            font-size: 12px;
            padding: 5px;
        }
        &__id {
            font-size: 12px;
            margin-top: 15px;
        }
        &__bonus {
            img {
                width: 20px;
                height: 20px;
            }
            font-size: 12px;
            margin-bottom: 6px;
        }
    }
}