.NewOrderPage{ 
  width: 1530px;
  height: fit-content;
  padding-top: 70px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  font-family: "Montserrat", sans-serif;

  &__phoneInput {
    width: 100%;
    height: 56px;
    &>div {
      width: 90px;
      button {
        width: 100%;
        height: 56px;
      }
    }
    input {
      width: 100%;
      height: 56px!important;
    }
  }

  * {
    box-sizing: border-box;
  }


  &__profilePlate {
    background: #fff;
    border-radius: 32px;
    padding: 15px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    width: 910px;

    &>div {
      width: 100% !important;
      justify-content: space-around;
    }
  }

  &__mainEditOrder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 775px;
    margin-top: 40px;
    transition: 1s;

    &__item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;

      &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 32px;
        line-height: 94%;
        color: #212b36;
        transition: .5s;

        img {
          filter: brightness(0);
          transition: .5s;
        }
      }

      &__body {
        margin-top: 15px;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 13px;
        transition: 1s;
        animation: showBody 1s;
        animation-delay: 0.00001s;

        &__inputRow {
          gap: 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;

          &__input {
            width: 360px;
            border: 1px solid #5fc56e;
            border-radius: 15px;
            padding: 15px 30px;
            height: 53px;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            color: #212b36;
            outline: none;
            transition: .5s;

            &__phoneInput {
              width: 360px;
              border: 1px solid #5fc56e;
              border-radius: 15px;
              overflow: hidden;
              height: 53px;
              outline: none;
              transition: .5s;
              &>div {
                height: 100%;
                width: 70px;
                button {
                  width: 100%;
                  height: 100%;
                }
              }
              input {
                width: 100% !important;
                height: 100% !important;
                font-weight: 500 !important;
                font-size: 20px !important;
                line-height: 120% !important;
                color: #212b36 !important;
              }
            }
          }
          &__inputRow {
            margin-top: 5px;
            height: 100px;
            width: 100%;
            border: 1px solid #5fc56e;
            border-radius: 15px;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            padding: 25px 30px;
            outline: none;
            transition: .5s;
            color: #212b36;
          }
        }
        &__timetitle {
          margin-top: 5px;
          width: 380px;
          font-weight: 400;
          font-size: 20px;
          line-height: 115%;
          color: #444;
          margin-top: 10px;
          height: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 20px;

          &__box {
            margin-top: 10px;
            height: fit-content;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;

            &__item {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #a8a9a6b3;
              border-radius: 32px;
              padding: 10px;
              width: 170px;
              height: 43px;
              font-weight: 500;
              font-size: 24px;
              line-height: 96%;
              color: #fff;
              transition: .5s;
              cursor: pointer;
            }
            .react-datepicker-wrapper {
              .react-datepicker__input-container {
                height: 47px;
                display: flex;
                align-items: center;
                gap: 10px;
              }
              width: 100%;
              height: 47px;
              gap: 10px;
              
            }
            .react-datepicker__tab-loop {
              .react-datepicker {
                border: 2px solid #5FC56E;
                .react-react-datepicker__navigation-icon::before {
                  border-color: #5dc571 !important;
                }
                .react-datepicker__header  {
                  background-color: #fff;

                  h2 {
                    color: #252525
                  }
                }
              }
            }
            &__date {
              border: 2px solid #5FC56E;
              height: 47px;
              width: 100%;
              border-radius: 10px;
              padding: 6px 10px 5px 35px !important;
              resize: none;
              font-size: 18px;
              outline: none;
            }
          }
        }
        &__delivery {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 509px;
          height: fit-content;
          
          &__item {
            margin-top: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &__payment {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              width: 90%;
              align-self: flex-end;
              margin-top: 50px;

              &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-weight: 500;
                font-size: 20px;
                letter-spacing: -0.01em;
                color: #000;

                &__change {
                  border: 2px solid #5fc56d;
                  border-radius: 32px;
                  padding: 10px;
                  width: 130px;
                  height: 44px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
            &__payBox {
              margin-top: 20px;
              width: 100%;
              height: fit-content;
              border-radius: 32px;
              background: #fff;
              padding: 10px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 10px;
              animation: showModal 1s;
              animation-delay: 0.0001s;
              &__item {
                transition: 1s;
                display: flex;
                align-items: center;
                justify-content: space-around;
                gap: 60px;
                font-weight: 500;
                font-size: 20px;
                letter-spacing: -0.01em;
                color: #000;
                cursor: pointer;
                padding: 10px 60px;
                border-radius: 32px;
                div {
                  text-wrap: nowrap;
                }
              }
            }

            input {
              align-self: flex-flex-start;
              margin-right: 20px;
              width: 28px;
              height: 28px;
              border-radius: 8px;
              border: 2px solid #5fc56e;
              outline: #5fc56d;
            }
            &__data {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                &__title {
                  font-weight: 600;
                  font-size: 24px;
                  color: #000;

                  span {
                    font-weight: 400;
                  }
                }
                &__price {
                  font-weight: 600;
                  font-size: 26px;
                  line-height: 88%;
                  color: #5fc56e;
                }
                &__save {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 20px;
                  font-weight: 500;
                  font-size: 20px;
                  letter-spacing: -0.01em;
                  color: #a8a9a6;
                }
                &__percent {
                  font-weight: 500;
                  font-size: 20px;
                  letter-spacing: -0.01em;
                  color: #f22f2f;
                }
              }
            }
          }
        }
      }
    }
    &__sendOrder {
      outline: none;
      border: none;
      position: relative;
      margin-top: 40px;
      margin-bottom: 40px;
      left: 20vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      padding: 10px;
      width: 276px;
      height: 59px;
      background: #5fc56e;
      font-weight: 400;
      font-size: 24px;
      letter-spacing: -0.01em;
      color: #fff;
      font-family: "Archivo Black", sans-serif;

      &:disabled {
        opacity: 0.5;
      }

      &__warn {
        text-align: center;
        margin-top: 10px;
        color: #f22f2f;
        font-weight: 600;
      }
    }
  }

  &__cart {
    background: #fff;
    width: 600px;
    max-height: 1000px;
    border-radius: 32px;
    padding: 20px 47px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    position: relative;

    &__viewBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background: #5fc56e;
      font-weight: 400;
      font-size: 24px;
      color: #fff;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    &__title {
      font-family: "Archivo Black", sans-serif;
      font-weight: 400;
      font-size: 32px;
      letter-spacing: -0.01em;
      color: #000;
      border-bottom: 2px solid #5fc56e;
      padding: 10px;
      width: 207px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__body {
      width: 100%;
      border-bottom: 2px solid #5fc56d;
      height: fit-content;
      padding-bottom: 25px;
      overflow-y: scroll;

      &__title {
        font-weight: 600;
        font-size: 18px;
        color: #A4A4A4;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        height: 45px;
        border-radius: 40px;
        font-weight: 400;
        font-size: 20px;
        
        // line-height: 29px; 
        line-height: 122%;
        text-align: center;
        color: #5dc571;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 20px;
        color: #000;
        span {
          width: 50px;
          text-align: center;
        }
        &>div {
          width: 220px;
        }
      }
    }
    &__footer {
      margin-top: 40px;
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      
      &__prices {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 10px;
        &__elem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-weight: 500;
          font-size: 24px;
          color: #000;
        }
      }
      &__total {
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__title {
          font-weight: 600;
          font-size: 24px;
          line-height: 96%;
          color: #000;
        }
        &__price {
          font-weight: 600;
          font-size: 24px;
          line-height: 96%;
          color: #5fc56e;
        }
      }
    }
  }
}

@keyframes showBody {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  } 
}

@media screen and (max-width: 1600px) {
  .NewOrderPage {
    width: fit-content;
    gap: 10px;
    &__profilePlate {
      width: 775px;
      padding: 15px 25px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .NewOrderPage {
    flex-direction: column-reverse;
    align-items: center;
    &__mainEditOrder__item__body {
      align-items: center;
    }
    &__cart {
      width: 775px;
      margin-top: 40px;
    }
    &__mainEditOrder__sendOrder {
      margin: auto;
      left: 0;
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 920px) {
  .NewOrderPage {
    width: fit-content;
    &__container {
      width: 700px;
    }
    &__profilePlate {
      width: 100%;
    }
    &__cart {
      width: 100%;
      margin-top: 0px;
    }
    &__mainEditOrder {
      width: 100%;

      &__item {

        &__header {
          
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .NewOrderPage {
    &__container {
      width: 500px;
    }
    &__profilePlate {
      width: 100%;
    }
    &__cart {
      width: 100%;
    }
    &__mainEditOrder {
      width: 100%;

      &__item {

        &__body {
          &__inputRow {
            flex-direction: column;
            &__input {
              width: 100%;
              &__phoneInput {
                width: 100%;
              }
            }
          }
          &__timetitle {
            width: 100%;
          }
        }
      }
    }
    &__cart {
      padding: 20px 10px;
      // margin-left: -10px;
    }
  }
}

@media screen and (max-width: 565px) {
  .NewOrderPage {
    padding: 30px 0;
    &__container {
      width: 360px;
    }
    &__profilePlate {
      width: 100%;
    }
    &__cart {
      margin-left: 0;
      margin-right: -10px;
      width: 95vw;
      padding: 10px 10px;
      &__title {
        font-size: 21px;
      }
      &__body {
        height: fit-content;
        &__item {
          font-size: 13px;
          &>div {
            width: 160px;
          }
        }
      }
      &__footer {
        margin-top: 20px;
        &__prices {
          &__elem {
            font-size: 13px;
          }
        }
        &__total {
          margin-top: 20px;
          margin-bottom: 20px;
          &__title {
            font-size: 13px;
          }
          &__price {
            font-size: 13px;
          }
        }
      }
    }
    &__mainEditOrder {
      &__sendOrder {
        font-size: 16px;
        margin-top: 10px;
        height: 30px;
        &__modal {
          box-sizing: border-box;
          width: 96% !important;
        }
      }
      &__item {
        &__header {
          font-size: 21px;
        }
        &__body {
          &__delivery {
            width: 100%;
            &__item {
              margin-top: 20px;
              width: 100%;
              &__data {
                &__row {
                  &__title {
                    font-size: 16px;
                  }
                  &__save {
                    font-size: 13px;
                  }
                  &__price {
                    font-size: 16px;
                  }
                }
              }
              &__payBox {
                &__item {
                  width: 100%;
                  box-sizing: border-box;
                  img {
                    width: 40px;
                  }
                  div {
                    font-size: 16px;
                  }
                  &__name {
                    display: none;
                  }
                }
              }
            }
          }
          &__timetitle {
            font-size: 12px;
            &__box {
              height: fit-content;
              &__item {
                font-size: 16px;
                height: 35px;
                width: 150px;
              }
            }
          }
          &__inputRow {
            &__inputRow {
              padding: 5px 10px;
              font-size: 13px;
              height: 60px;
              border-radius: 10px;
            }
            &__input {
              font-size: 13px;
              height: 30px;
              border-radius: 10px;
              padding: 5px 10px;
              &__phoneInput {
                height: 30px;
                border-radius: 10px;
                input {
                  font-size: 13px !important;
                }
              }
            }
          }
          &__timetitle {
            width: 100%;
          }
        }
      }
    }
    &__cart {
      padding: 20px 10px;
      margin-left: -10px;
      width: 95vw;
    }
  }
}

@media screen and (max-width: 390px) {
  .NewOrderPage {
    width: 310px;
    padding: 20px 0;
    &__container {
      width: 310px;
    }
    &__profilePlate {
      width: 100%;
    }
    &__cart {
      margin-left: 0!important;
      margin-right: 0;
      width: 98vw;
      padding: 10px 10px;
      &__title {
        font-size: 21px;
      }
      &__body {
        height: fit-content;
        &__item {
          font-size: 13px;
          &>div {
            width: 140px;
          }
        }
      }
      &__footer {
        margin-top: 20px;
        &__prices {
          &__elem {
            font-size: 13px;
          }
        }
        &__total {
          margin-top: 20px;
          margin-bottom: 20px;
          &__title {
            font-size: 13px;
          }
          &__price {
            font-size: 13px;
          }
        }
      }
    }
    &__mainEditOrder {
      &__sendOrder {
        font-size: 16px;
        margin-top: 10px;
        height: 30px;
        &__modal {
          padding: 20px!important;
        }
      }
      &__item {
        &__header {
          font-size: 21px;
        }
        &__body {
          &__delivery {
            width: 100%;
            &__item {
              margin-top: 20px;
              width: 100%;
              .MuiSvgIcon-root {
                font-size: 30px !important;
              }
              &__payment {
                align-self: center;
                margin-top: 30px;
                img {
                  height: 40px;
                }
                div {
                  font-size: 13px;
                }
                &__change {
                  height: 30px;
                  width: 85px;
                }
              }
              &__payBox {
                &__item {
                  width: 100%;
                  box-sizing: border-box;
                }
              }
            }
          }
          &__timetitle {
            font-size: 12px;
            &__box {
              height: 85px;
              &__item {
                font-size: 16px;
                height: 35px;
                width: 150px;
              }
            }
          }
          &__inputRow {
            &__inputRow {
              padding: 5px 10px;
              font-size: 13px;
              height: 60px;
              border-radius: 10px;
            }
            &__input {
              font-size: 13px;
              height: 30px;
              border-radius: 10px;
              padding: 5px 10px;
              &__phoneInput {
                height: 30px;
                border-radius: 10px;
                input {
                  font-size: 13px !important;
                }
              }
            }
          }
          &__timetitle {
            width: 100%;
          }
        }
      }
    }
    &__cart {
      padding: 20px 10px;
      margin-left: -10px;
    }
  }
}

.react-datepicker__day--disabled {
  background-color: #ff000033;
  color: #ff0000 !important;
  border-radius: 5px;
}

.day-wrapper.disabled-day {
  background-color: #c3c3c3;
  color: white;
  border-radius: 5px;
}
.day-wrapper {
  border-radius: 5px;
}

.react-datepicker__day--disabled:hover {
  background-color: #ff000066 !important;
}