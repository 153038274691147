.categoriesItem {
  width: 165px;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #fff;
  border-radius: 25px;

  img {
    width: 100%;
    height: 70%;
    object-fit: contain;
  }

  p {
    color: #4c4c4c;
    font-family: Montserrat;;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;

    margin: 0;
  }
}
