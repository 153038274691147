.OwnerPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin: auto;
  font-family: "Inter", sans-serif;
  padding: 10px 20px;
  box-sizing: border-box;

  &__inputPaid {
    background: transparent;
    border-bottom: 1px solid #5fc56e !important;
    outline: none;
  }

  &__editBar {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    width: 100%;
    height: fit-content;
    gap: 10px;
    box-sizing: border-box;
  }

  &__row {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 25px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  section {
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    padding: 7px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  &__imageBox {
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    width: 146px;
    height: 146px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    img {
      height: 100%;
      width: 100%;
    }
  }

  &__infoUser {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 7px 7px;

    h2 {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #252525;
      margin: 0px;
      margin-top: 5px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #252525;
      margin: 0px;
    }
    img {
      height: 40px;
      margin-top: 10px;
    }
    button {
      outline: none;
      border: none;
      width: 100%;
      height: 30px;
      background: #5fc56e;
      border-radius: 8px;
      margin-top: auto;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
    }

    &__modal {
      &__codeBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        height: auto;
        overflow-y: scroll;

        &__item {
          width: 100%;
          display: flex;
          margin: 0;
        }
      }
      &__inputBox {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        input {
          outline: none;
          border: 2px solid #5fc56e;
          border-radius: 8px;
          height: 30px;
          width: 100%;
          box-sizing: border-box;
          padding: 0 5px;
          color: #636363;
        }
        button {
          font-size: 24px;
          width: 30px;
          margin: 0px;
        }
      }
      &__passwordBox {
        display: flex;
        flex-direction: column;
        align-items: center; 
        justify-content: center;
        height: 100%;
        width: 100%;

        input {
          height: 80px;
          width: 100%;
          border: none;
          outline: none;
          font-size: 48px;
          color: #5fc56e;
          letter-spacing: 0.25em;
          font-weight: 700;
          text-align: center;
        } 
      }
    }
  }

  &__infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    padding: 0px 10px;


    &__select {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 7px;

      font-weight: 900;
      font-size: 12px;
      text-align: center;
      color: #286f29;
      margin-right: 15px;
    }

    &__turnover {
      border: 1px solid #286f29;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #286f29;
    }

    &__profit {
      border: 1px solid #5fc56e;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #5fc56e; 
    }

    &__debt {
      border: 1px solid #ff725e;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #ff725e; 
    }

    &__persents {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: auto;
      width: fit-content;
      height: fit-content;
      gap: 15px;
      div {
        flex: 1;
        text-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
  }

  &__infoStats {
    // margin-left: auto;
    width: 100%;
    height: 100%;

    img {
      height: 100%;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    width: 100%;
    height: fit-content;
    position: relative;

    input {
      width: 20px;
      height: 20px;
      border: none;

      
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 45px;
      margin-bottom: 10px;

      &__headerColumn {
        text-align: center;

        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #d8d8d8;
      }

      &__background {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 45px;
        border-radius: 6px;
        background: #f2f2f2;
        // padding: 0px 7px;

        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #252525;

        button {
          border-radius: 3px;
          background: #5fc56e;
          color: #fff;
          padding: 5px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 5px;

          outline: none;
          border: none;
          height: 30px;
            
          font-weight: 700;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }

  &__dayCard {
    display: flex;
    flex-direction: column;
    width: 250px;
    border-radius: 8px;
    overflow: hidden;
    height: fit-content;
    background: #fff;
    box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.25);

    &__title {
      width: 100%;
      height: 30px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
    &__dataShow {
      display: flex;
      width: 100%;
      height: fit-content;
      background: #f5f5fd;

      &__column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 5px;

        div {
          border-radius: 5px;
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          color: #252525;
        }

        &__title {
          background: #fff;
        }
      }

      &__notWork {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #252525;
      }
    }
  }

  &__modal {
    h4 {
      margin: 0px;

    }
    &__data {
      height: 300px;
      overflow-y: scroll;
      gap: 5px;
      display: flex;
      flex-direction: column;
      
      &__item {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        color: #252525;
        font-weight: 400;
        font-size: 14px;

        button:disabled {
          opacity: .5;
        }

        &__number {
          width: 10px;
        }

        &__description {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 12px;
              flex: 1;
            }
            span:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }
    &__bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      div {
        font-weight: 600;
      }
      button:disabled {
        opacity: .7;
      }
    }

    &__addPayment {
      h4 {
        margin: 0px;
      }
      
      &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          color:#252525;
          margin: 0px;
        }

        input {
          outline: none;
          border: 1px solid #5fc56e;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 10px 5px;
        }
        select {
          outline: none;
          border: 1px solid #5fc56e;
          border-radius: 6px;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          padding: 10px 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .OwnerPage {
    &__row:nth-child(1) { 
      flex-wrap: wrap;
      .OwnerPage__infoUser {
        width: 40%;
      }
      section:nth-child(2) {
        flex-wrap: wrap;
        height: fit-content !important;
      }
    }
    .OwnerPage__infoBar { 
      flex-wrap: wrap;
      &__select {
        margin-right: 0;
      }
      div {
        flex: 1;
      }
    }
    &__row:nth-child(4) { 
      overflow-x: scroll;
    }
    &__table {
      min-width: 1024px;
      // overflow-x: scroll;
      // overflow-y: hidden;
      ::-webkit-scrollbar {
        height: 5px;
        background: #f2f2f2;
      }
    }
  }
}