.categoriesMain{
  // margin-top: 70px;
  width: 100vw;
  box-sizing: border-box;
  padding: 56px 20px 109px 20px;
  background: #E1E1E0;

  &__category {
    display: flex;
    align-items: center;
    width: 80vw;
    margin: auto;
    margin-bottom: 111px;

    &__title {
      color: #000;
      font-family: Montserrat;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 3px;
      margin-right: 30px;

      &__filter {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #000;
        img {
          width: 38px;
          height: 38px;
        }
      }
    }
    span {
      margin-left: 30px;
      color: #4C4C4C;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 3px;
    }
  }
}


.categoriesPage {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin-left: -20px;
  margin: auto;
  gap: 20px;
  margin-top: 20px;
}
.ShadowPage__search {
  width: 100%;
  height: fit-content;
  padding: 10px 0 30px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #a4a4a4;

  &__clearBtn {
    border: 2px solid #5fc56e;
    background: #5fc56e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 10px;
    border-radius: 50px;
    margin-left: -45px;
    transition: 1s;
    animation-delay: .1s;
    animation: showAnim 1s ease-out;
  }

  input {
    border: 2px solid #5fc56e;
    border-radius: 10px;
    width: 180px;
    height: 40px;
    padding: 5px 20px;
    font-family: 'Montserrat';
    font-size: 18px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 992px) {
  .categoriesPage {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .categoriesMain {
    width: 100vw;
    box-sizing: border-box;
    padding: 10px 25px 60px 25px;
    &>div {
      margin: auto;
    }
    &__title {
      display: none;
    }
    &__category {
      display: none;
    }
  }
  
}
@media screen and (max-width: 480px) {
  .categoriesMain {
    padding: 10px 15px 60px 15px;
    // &>div {
    //   margin: auto;
    // }
    // &__title {
    //   display: none;
    // }
    // &__category {
    //   display: none;
    // }
  }
  
}
@media screen and (max-width: 370px) {
  .categoriesMain {
    padding: 10px 0px 60px 0px;
    // &>div {
    //   margin: auto;
    // }
    // &__title {
    //   display: none;
    // }
    // &__category {
    //   display: none;
    // }
  }
  .categoriesPage {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.SPOBox {
  background: #f8d36c;
  border: 3px solid #f8d36c;
  border-radius: 20px;
  width: 110%;
  height: fit-content;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 122%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }
  &__list {
    border-radius: 14px;
    background: #e1e1e0;
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;  
    flex-wrap: wrap;
    padding: 10px 10px;
    box-sizing: border-box;
  }
}