.WarningModal {
  position: fixed;
  z-index: 51;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.174);

  &__content {
    width: 300px;
    min-height: 300px;
    max-height: 720px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 32px;
    padding: 20px 10px;
    box-sizing: border-box;
    text-align: center;
    animation: showModal .5s;
    animation-delay: 0.000001s;

    &__close {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -10px;
      img {
        width: 15px;
      }
    }
    &__children {
      width: 100%;
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}