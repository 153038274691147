.PreviewFilter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50px;
    max-width: fit-content;
    background-color: #FFF;
    border: 2px solid #fff;
    max-height: 50px;

    &__background {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: linear-gradient(90deg, #9BE15D 0%, #00E3AE 100%);
      width: 100%;
      padding: 31px 40px;
      margin-left: -20px;
    }

    &__catalog{
        height: 50px;
        background: #5FC56E;
        border-radius: 50px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10 !important;
        margin-right: 40px;
        cursor: pointer;
        
        span{
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          color: #FFF;
      
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.8px;
          padding: 0 20px;
        }
        img{
          display: flex;
          align-items: center;
          width: 20px;
          height: 10px;
        }
    }
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        
        &__items {
          position: absolute;
          top: 60px;
          left: -25px;
          width: 100%;
          max-height: 340px;
          overflow-y: scroll;
          overflow-x: hidden;
          background: #fff;
          border-radius: 16px;
          filter: drop-shadow(5px 5px 10px rgba(117, 117, 117, 0.271));
          transition: .5s all;
          animation: showModal .5s;
          animation-delay: 0.000001s;
          z-index: 10 !important;
          &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #E8E8E8;
            padding: 10px 20px;
            box-sizing: border-box;
            font-size: 20px;
            font-family: Montserrat, sans-serif;
            transition: .4s all;
            &:hover {
              filter: brightness(.7);
            }
            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }
        }
    }
    &__button{
        outline: none;
        background-color: transparent;
        border: none;
        display: flex;
        color: #000;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 3px;
        padding: 9px;
        margin-right: 50px;
        min-width: 140px;
        border-bottom: 2px solid transparent;
        transition: border-color .5s;
        cursor: pointer;

        &:hover{
            border-bottom: 1px solid #5FC56E;
        }
    }
}

@media screen and (max-width: 1400px) {
  .PreviewFilter__background {
    display: none;
  }
}

@keyframes showModal {
  0% {
    opacity: 0;transform: translateY(-200px);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;transform: translateY(0px);
  }
}