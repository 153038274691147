.CartElemInfoModal {
  position: fixed;
  top: 30vh;
  
  margin: auto;
  border: 2px solid #5fc56e;
  border-radius: 32px;
  width: 382px;
  height: 384px;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  transition: 1s;
  animation: UpSlide .5s ease-in-out;
  animation-iteration-count: 1;
  margin: 0 35vw;
  z-index: 51;


  &__item {
    width: 100%;
    div {
      margin-right: 10px;
      width: 84px;
      height: 30px;
      border-radius: 43px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 400;
      font-size: 18px;
      
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000;
  }
}

@media screen and (max-width: 768px) {
  .CartElemInfoModal {
    width: 332px;
    height: 384px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 9px;
  }
}

@keyframes UpSlide {
  0% {
    transform: translateY(-100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
