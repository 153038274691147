.loop-wrapper-loader {
  margin: 0 auto;
  position: relative;
  display: block;
  width: 380px;
  height: 150px;
  overflow: hidden;
  border-bottom: 3px solid #fff;
  color: #fff;
  margin-bottom: 10px;
}
.mountain-loader {
  position: absolute;
  right: -900px;
  bottom: -20px;
  width: 2px;
  height: 2px;
  box-shadow: 
    0 0 0 50px #5FC56E,
    60px 50px 0 70px #5FC56E,
    90px 90px 0 50px #5FC56E,
    250px 250px 0 50px #5FC56E,
    290px 320px 0 50px #5FC56E,
    320px 400px 0 50px #5FC56E
    ;
  transform: rotate(130deg);
  animation: mtn 20s linear infinite;
}
.hill-loader {
  position: absolute;
  right: -900px;
  bottom: -50px;
  width: 400px;
  border-radius: 50%;
  height: 20px;
  box-shadow: 
    0 0 0 50px #5FC56E,
    -20px 0 0 20px #5FC56E,
    -90px 0 0 50px #5FC56E,
    250px 0 0 50px #5FC56E,
    290px 0 0 50px #5FC56E,
    620px 0 0 50px #5FC56E;
  animation: hill 4s 2s linear infinite;
}
.tree-loader, .tree-loader:nth-child(2), .tree-loader:nth-child(3) {
  position: absolute;
  height: 100px; 
  width: 35px;
  bottom: 0;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/tree.svg) no-repeat;
}
.avacado-loader {
  position: absolute;
  height: 40px; 
  width: 35px;
  bottom: 0;
  background: url(https://www.svgrepo.com/show/404748/avocado.svg) no-repeat;
  background-size: contain;
  opacity: 0;
}
.apple-loader {
  position: absolute;
  height: 40px; 
  width: 35px;
  bottom: 0;
  background: url(https://www.svgrepo.com/show/484901/apple.svg) no-repeat;
  background-size: contain;
  opacity: 0;
}
.rock-loader {
  margin-top: -17%;
  height: 2%; 
  width: 2%;
  bottom: -2px;
  border-radius: 20px;
  position: absolute;
  background: #ddd;
}
.truck-loader, .wheels-loader {
  transition: all ease;
  width: 85px;
  margin-right: -60px;
  bottom: 0px;
  right: 50%;
  position: absolute;
  background: #eee;
}
.truck-loader {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/truck.svg) no-repeat;
  background-size: contain;
  height: 60px;
}
.truck-loader:before {
  content: " ";
  position: absolute;
  right: 60px;
  width: 25px;
  box-shadow:
    -30px 28px 0 1.5px #fff,
     -35px 18px 0 1.5px #fff;
}
.wheels-loader {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/wheels.svg) no-repeat;
  height: 15px;
  margin-bottom: 0;
}
.hover {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(225,225,224);
background: linear-gradient(90deg, rgba(225,225,224,1) 0%, rgba(9,121,17,0) 30%, rgba(9,121,17,0) 70%, rgba(225,225,224,1) 100%);
}

.tree-loader  { animation: tree 3s 0.000s linear infinite; }
.tree-loader:nth-child(2)  { animation: tree2 2s 0.150s linear infinite; }
.tree-loader:nth-child(3)  { animation: tree3 8s 0.050s linear infinite; }
.avacado-loader  { animation: avacado 5s 0.050s linear infinite; }
.apple-loader  { animation: avacado 5s 2.050s linear infinite; }
.rock-loader  { animation: rock 4s   -0.530s linear infinite; }
.truck-loader  { animation: truck 4s   0.080s ease infinite; }
.wheels-loader  { animation: truck 4s   0.001s ease infinite; }
.truck-loader:before { animation: wind 1.5s   0.000s ease infinite; }


@keyframes tree {
  0%   { transform: translate(1350px); }
  50% {}
  100% { transform: translate(-50px); }
}
@keyframes tree2 {
  0%   { transform: translate(650px); }
  50% {}
  100% { transform: translate(-50px); }
}
@keyframes tree3 {
  0%   { transform: translate(2750px); }
  50% {}
  100% { transform: translate(-50px); }
}
@keyframes avacado {
  0%   { transform: translate(500px); opacity: 1;}
  50% { transform: translate(400px); opacity: 1;}
  100% { transform: translate(200px); opacity: 1;}
}

@keyframes rock {
  0%   { right: -200px; }
  100% { right: 2000px; }
}
@keyframes truck {
  0%   { }
  6%   { transform: translateY(0px); }
  7%   { transform: translateY(-6px); }
  9%   { transform: translateY(0px); }
  10%   { transform: translateY(-1px); }
  11%   { transform: translateY(0px); }
  100%   { }
}
@keyframes wind {
  0%   {  }
  50%   { transform: translateY(3px) }
  100%   { }
}
@keyframes mtn {
  100% {
    transform: translateX(-2000px) rotate(130deg);
  }
}
@keyframes hill {
  100% {
    transform: translateX(-2000px);
  }
}


.loader{
  position: absolute;
  bottom: 12px;
  right: 170px;
  text-align: center;
  display: inline-block;
  vertical-align: top;

  svg path,
  svg rect {
    fill: #fff;
  }
}

/*
  Set the color of the icon
*/