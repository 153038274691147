.MoreTabSideBar {
    
    &__background {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 20;
    }
    &__sidebar {
        overflow-y: hidden;
        bottom: 0px;
        left: 0px;
        position: fixed;
        z-index: 51!important;
        background: linear-gradient(90deg, #9BE15D 0%, #00E3AE 100%); 
        border-top-right-radius: 50px; 
        width: 182px;
        max-height: 700px;
        box-sizing: border-box;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;

        
        &__close {
            width: 100%;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
                width: 24px;
            }
        }
        &__tabs {
            width: 100%;
            display: flex;
            flex-direction: column;
            // margin-top: 7px;

            &__container {
                width: 100%;
                // margin-bottom: 10px;

                &__item {
                    border: none;
                    outline: none;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    background: #fff;
                    border-radius: 32px;
                    margin-bottom: 10px;
    
                    color: #5FC56D;
                    font-size: 20px;
                    font-family: 'Archivo Black';
                    font-weight: 400;
                    letter-spacing: 3px;
                    img {
                        margin-left: 10px;
                        height: 21px;
                        width: fit-content;
                    }
                }
                &__options {
                    background-color: #fff;
                    border-radius: 20px;
                    max-height: 420px;
                    overflow-y: scroll;
                    margin-bottom: 10px;
                    &__item {
                        padding: 10px 10px;
                        border-bottom: 1px solid #00000061;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        font-family: Montserrat, sans-serif;
                        font-weight: 500px;
                        font-size: 20px;
                        img {
                            height: 40px;
                            width: 40px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        &__category {
            width: 80%;
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            &__item {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                color: white;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 700;
                letter-spacing: 1.40px;
            }
        }
        &__settings {
            width: 100%;
            margin-top: 70px;

            :last-child {
                :last-child {
                    opacity: 0;
                }
            }

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 10px;

                color: white;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 400;
                img {
                    width: 20px;
                }
            }
        }
        &__info {
            margin-top: 30px;
            display: flex;
            flex-direction: column;

            &__social {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                img {
                    width: 30px;    
                }
            }
            &__phone {
                width: 100%;
                margin-top: 5px;
                display: flex;
                flex-direction: column;

                color: black;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 400;
                letter-spacing: 1.80px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .MoreTabSideBar {
        &__sidebar {
            width: 310px;
            max-height: 700px; 

            &__close {
                img {
                    width: 30px;
                }
            }
            &__tabs {
                &__item {
                    margin-bottom: 20px;
                    font-size: 28px;
                    padding: 10px 30px;
                    img {
                        height: 40px;
                    }
                }
            }
            &__category {
                &__item {
                    font-size: 30px;
                }
            }
            &__settings {
                align-self: flex-flex-start;
                margin: 20px 0;
                width: 60%;
                &__item {
                    font-size: 15px;
                }
                img {
                    width: 20px;    
                }
            }
            &__info {
                &__social {
                    img {
                        width: 30px;
                    }
                }
                &__phone {
                    margin-top: 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .MoreTabSideBar {
        &__sidebar {
            max-height: 680px; 
            &__close {
                img {
                    width: 20px;
                }
            }
        }
    }
    
}
// @media screen  and (max-width: 576px) {
//     .MoreTabSideBar {
//         &__sidebar {
//             width: 100%;
//             height: 100vh; 

//             &__close {
//                 img {
//                     width: 30px;
//                 }
//             }
//             &__tabs {
//                 &__item {
//                     margin-bottom: 16px;
//                     font-size: 24px;
//                     padding: 8px 20px;
//                     img {
//                         height: 34px;
//                     }
//                 }
//             }
//             &__category {
//                 &__item {
//                     font-size: 26px;
//                     margin-bottom: 10px;
//                 }
//             }
//             &__settings {
//                 margin-top: 20px;
//                 &__item {
//                     font-size: 30px;
//                 }
//                 img {
//                     width: 40px;    
//                 }
//             }
//             &__info {
//                 margin-top: 40px;
//                 &__social {
//                     img {
//                         width: 60px;
//                     }
//                 }
//                 &__phone {
//                     margin-top: 20px;
//                     font-size: 30px;
//                 }
//             }
//         }
//     }
// }