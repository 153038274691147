.TemplateItemEditCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 5px;
  border: 1px solid #ccc;
  border-radius: 32px;
  width: 154px;
  height: 30px;

  &__decrement {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 32px;
    width: 50px;
    height: 33px;
    background: #9be15d;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #fff;
  }

  &__quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.12em;
    text-align: center;
    color: #252525;

    input {
      border: none;
      outline: none;
      text-align: center;
      display: flex;
      align-items: center;
      justify-self: center;
      width: 100%;
    }
  }

  &__increment {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 32px;
    width: 50px;
    height: 33px;
    background: #00e3ae;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #fff;
  }
}