.TemplateItemEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 10px;
  gap: 5px;
  

  &__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    gap: 5px;
    width: 100%;
    
    
    &__img {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.14em;
      color: #252525;
      text-align: start;
    }
  }
  &__dataParams {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.14em;
    text-align: center;
    color: #000;
    gap: 5px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #E1E1E1;

    &__params {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.14em;
      text-align: center;
      color: #000;
      gap: 5px;
      display: flex;
      flex-direction: column;

      input {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.12em;
        text-align: center;
        color: #000;
        outline: none; 

        border: 1px solid #252525;
        border-radius: 10px;
        width: 130px;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }
    &__total {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 35px;
      border-radius: 32px;
      width: 90px;
      height: 32px;
      background: linear-gradient(90deg, #9be15d 0%, #00e3ae 90%);

      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.12em;
      text-align: center;
      color: #fff;
    }
    &__deleteBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      background: #9be15d;
      font-weight: 400;
      font-size: 32px;
      letter-spacing: 0.06em;
      text-align: center;
      color: #fff;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}