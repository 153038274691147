.LineCard {
  &__aprox {
    font-size: 18px;
  } 
  &__div {
    display: flex;
    gap: 20px;
    width: fit-content;
    align-items: center;
    
    span {
      color: #838383 !important;
      width: fit-content;
      text-wrap: nowrap !important;
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .LineCard {
    &__aprox {
      font-size: 12px;
    } 
    &__div {
      display: flex;
      gap: 0px;
      width: fit-content;
      align-items: center;
      
      &__text {
        font-size: 10px !important;
      }
    }  
  }
}