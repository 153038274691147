.Timer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  height: fit-content;
  flex-direction: column;
  font-family: 'Montserrat';
  font-size: 60px;
  font-weight: 700;
  gap: 10px;
  p {
    font-size: 60px;
    font-weight: 700;
    width: 170px;
    gap: 10px;
    color: #c55fb6;
    margin: 0;
    margin-right: 10px;
  }
  span {
    font-weight: 600;
    font-size: 38px;
    color: #5FC56E;
  }
}

.AlpabetSortBtn {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  background: #5FC56E;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
  }
}


.SalePage {

  &__shareBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px !important;
    img {
      border-radius: 12px;
      width: 54px;
      height: 54px;
      padding: 10px;
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(197, 95, 182, 0.7) 0%, rgba(160, 39, 143, 0.7) 100%);
      transition: all 1s ease-in-out;
      display: flex;
    }
    img:hover {
      background: linear-gradient(180deg, rgba(175, 86, 161, 0.9) 0%, rgba(152, 38, 137, 0.9) 100%);
    }
    img:active {
      background: linear-gradient(180deg, rgba(142, 69, 131, 0.9) 0%, rgba(116, 29, 104, 0.9) 100%);
    }
    &__text {
      font-weight: 600;
      font-size: 18px;
      color: #c55fb6;
      width: 165px;
    }
  }
}