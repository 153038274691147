.previewNav__menu {
    // position: relative;
    
    &__count {
        position: relative;
        margin-top: -30px;
        margin-right: -20px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        background: #00c668;
        color: #fff;
        z-index: 1;
        font-family: Montserrat, sans-serif;
    }
    &__modal {
        z-index: 50;
        &__wrapper {
            background: rgba(0, 0, 0, 0.315);
            z-index: 50;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-tracks: center;
        }
        margin: auto;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    }
}

@media screen and (max-width: 1400px) {
    .previewNav__menu {
        position: relative;
    }
}

@media screen and (max-width: 992px) {
    .previewNav__menu {
        &__count {
            font-family: "Montserrat", sans-serif;
            position: relative;
            margin-top: -24px;
            margin-right: -12px;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 32px;
            background: #00c668;
            color: #fff;
            z-index: 1;
            font-size: 10px;
        }
        img {
            width: 30px;
            margin: 0 2px;
        }
    }   
}