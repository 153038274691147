.login__btn{
  min-width: 175px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 1px solid #E8E8E8;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  font-family: Montserrat !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  
  &:active{
    opacity: 0.7;
  }

  img{
    margin-right: 10px;
  }
}

.login__btn::placeholder{
  color: #C2C2C2;

  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.4px;
  text-wrap: nowrap;
}


.login__btn:focus{
  outline: none;
  border: 1px solid black;

}

.login__btn > div {
  border-radius: 50px;
}  

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .login__btn {
    min-width: 84px !important;
    height: 60px;
    padding: 10px;
    box-sizing: border-box !important;

    color: rgb(36, 29, 29);
    font-size: 24px !important;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.40px;
    img {
      height: 25px;
    }
    div {
      min-width: 84px !important;
      height: 60px;
      text-wrap: nowrap;
    }
  }
}

@media screen and (max-width: 992px) {
  .login__btn {
    min-width: 84px !important;
    height: 30px;
    padding: 10px;
    box-sizing: border-box !important;

    color: rgb(36, 29, 29);
    font-size: 12px !important;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.40px;
    img {
      height: 15px;
    }
    div {
      min-width: 84px !important;
      height: 30px;
      text-wrap: nowrap;
    }
  }
}