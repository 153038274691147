.ThanksModal {
    * {
        margin: 0;
        padding: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 200px;
    margin-bottom: 200px;
    padding: 20px;
    width: 600px;
    height: fit-content;
    border-radius: 10px;
    background: #FFF;
    text-align: center;
    font-family: Montserrat;
    color: #4C4C4C;


    &__h1 {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 3.2px;
    }
    &__p {
        margin-bottom: 20px;
        width: 90%;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1.6px;
    }
    &__h3 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1.6px;
    }

    &__numberContainer {
        display: flex;
        align-items: center;

        *{
            padding: 5px;
        }

        &__payNumber {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 2px;
        }

        img {
            transition: .2s;
            &:hover {
                opacity: .4;
            }
            &:active {
                filter: contrast(0%);
                filter: brightness(1%);
                opacity: 1;
            }
        }
    }

    &__backBtn {
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 40px;
        border-radius: 10px;
        background: #5FC56E;
        transition: 0.5s;

        a {
            text-decoration: none;
            color: #FFF;

            &:hover {
                color: #5FC56E;
            }
        }
        &:hover {
            background: #f2f2f2;
        }
    }
}

@media screen and (max-width: 768px) {
    .ThanksModal {
        width: 500px;
    }
}

@media screen and (max-width: 576px) {
    .ThanksModal {
        width: 100%;
        box-sizing: border-box;

        &__numberContainer {
            flex-direction: column;
        }
    }
    
}