.Preview { 
    width: 100vw;
    // margin-left: -20px;
    background: #E1E1E0;
    padding-bottom: 40px;

    &All {
        display: flex;
        flex-direction: column;

        width: 80%;
        margin: auto;
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 3px;
        margin-bottom: 40px;

        &__title {
            margin-top: 60px;
            margin-bottom: 40px;

            color: #000;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 300;
            display: flex;
            align-items: center;

            img {
                margin-right: 20px;
            }
        }

        &__categories {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            width: 100%;

            &__item {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                cursor: pointer;

                color: #000;
                font-size: 32px;
                font-weight: 300;
                text-wrap: wrap;
                text-align: center;
                width: 220px;
                height: 350px;

                img {
                    border-radius: 30px;
                    width: 220px;
                    height: 220px;
                }
            }
        }
        &__brands {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            
            &__item {
                margin: 0px 30px;
                margin-top: 30px;
                overflow: hidden;
                background-color: #fff;
                border-radius: 30px;
                width: 200px;
                height: 200px;
                filter: drop-shadow(7px 8px 10px rgba(0, 0, 0, 0.25)); 
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                
                img {
                    border-radius: 30px;
                    width: 100%;
                }
            }
        }
        &__businessType {
            display: flex;
            flex-wrap: wrap;

            &__item {
                margin-right: 20px;
                cursor: pointer;
                img {
                    border-radius: 30px;
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .PreviewAll {
        width: 90%;

        &__categories {
            &__item {
                width: 180px;
                height: 310px;
                text-align: center;
                overflow: hidden;
                img {
                    border-radius: 30px;
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .PreviewAll {
        width: 90%;
        &__title {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        &__categories {
            &__item {
                width: 70px;
                height: 130px;

                text-align: center; color: black; font-size: 12px; font-family: Montserrat; font-weight: 400;
                img {
                    border-radius: 13px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
    
}