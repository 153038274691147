.categoriesMain{
  // margin-top: 70px;
  width: 100vw;
  box-sizing: border-box;
  padding: 56px 20px 109px 20px;
  background: #E1E1E0;

  &__category {
    display: flex;
    align-items: center;
    width: 80vw;
    margin: auto;
    margin-bottom: 111px;

    &__title {
      color: #000;
      font-family: Montserrat;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 3px;
      margin-right: 30px;

      &__filter {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #000;
        img {
          width: 38px;
          height: 38px;
        }
      }
    }
    span {
      margin-left: 30px;
      color: #4C4C4C;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 3px;
    }
  }
}


.categoriesPage {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin-left: -20px;
  margin: auto;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .categoriesPage {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .categoriesMain {
    width: 100vw;
    box-sizing: border-box;
    padding: 10px 25px 60px 25px;
    &>div {
      margin: auto;
    }
    &__title {
      display: none;
    }
    &__category {
      display: none;
    }
  }
  
}
@media screen and (max-width: 480px) {
  .categoriesMain {
    padding: 10px 15px 60px 15px;
    // &>div {
    //   margin: auto;
    // }
    // &__title {
    //   display: none;
    // }
    // &__category {
    //   display: none;
    // }
  }
  
}
@media screen and (max-width: 370px) {
  .categoriesMain {
    padding: 10px 0px 60px 0px;
  }
  .categoriesPage {
    gap: 5px;
  }
}

.contentCardPro {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 400;
  padding: 10px 5px;
  border-radius: 32px;
  font-family: 'Montserrat';
}
@media screen and (max-width: 576px) {
  .contentCardPro {
    font-size: 14px;
  }
}