.additionalInfo {
  width: 30%;
  background-color: #fff;
  border-radius: 4px;

  height: max-content;

  &__content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__title {
        color: #a4a4a4;
        font-family: 'Archivo Black';
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 798px) {
  .additionalInfo {
    width: 100%;
  }
}
