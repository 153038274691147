.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20 !important;

  .itemModal::-webkit-scrollbar {
    width: 10px!important;
  }
  
  
  .itemModal {
    position: relative;
    top: 100px;
    margin: auto;
    height: 600px;
    width: 520px;
    background: #FFF;
    border-radius: 10px 10px;
    overflow: auto;
    z-index: 50 !important;

    &__photos {
      margin-left: 20px;
      margin-right: 20px;
      height: 360px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        margin-top: 10px;
      }

      &__multi {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__main {
        width: 100%;
        background: red;
      }
    }

    &__baseInfo {
      margin: 25px 20px 0 20px;

      &__title {
        color: #4C4C4C;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.8px;
      }

      &__tags {
        margin-top: 10px;

        span {
          height: 24px;
          max-width: 60px;
          background: #5FC56E;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFF;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.4px;
        }
      }

      &__price {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__price {
          margin-top: 14px;
          color: #4C4C4C;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 1.6px;

          span {
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }

        &__buttons {
          display: flex;
          justify-content: space-between;
          width: 60px;
        }
      }
    }

    &__separator {
      margin-top: 13px;
      width: 500px;
      height: 1px;
      background: #E8E8E8;
    }

    &__addition {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 10px;
      color: #4C4C4C;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.4px;

      .title {
        margin-top: 10px;

        span {
          color: #BBB;
        }
      }

      &__title {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
      &__tags{
        margin-top: 10px;
      }

      &__brand {
        color: #5FC56E;

      }

      &__category {

      }

      &__clientType {

      }

      &__structure {

      }

      &__similar {
        margin-top: 10px;

        &__buttons {
          margin-top: 6px;

          display: flex;
          align-items: center;
          flex-direction: row;

          &__this {
            width: 111px;
            height: 24px;
            background: #5FC56E;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #FFF;
            }
          }

          &__all {
            margin-left: 7px;
            width: 40px;
            height: 24px;
            border-radius: 10px;
            background: #E8E8E8;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #4C4C4C;
            }
          }
        }

        &__photos {
          height: 100px;
        }
      }
    }
  }
  &__bottom {
    border-radius: 0 0 10px 10px;
    position: relative;
    margin: auto;
    bottom: -30px;
    left: 0;
    width: 520px;
    height: 80px;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102!important;

    &__button {

      display: flex;
      justify-content: center;
      align-items: center;
      width: 462px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #5FC56E;

      span {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.6px;
        
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .itemModal {
    width: 90% !important;

    &__photos {
      width: 100%;
      margin: 0 !important;
      box-sizing: border-box;
      &__multi {
        width: 100%;
        display: none!important;
      }
      img {
        margin: auto;
        height: 100%;
      }
    }
  }
  .main__bottom {
    width: 90% !important;
    // z-index: 100!important;
    &__button {
      width: 80%!important;
      height: 50%!important;
    }
  }
}