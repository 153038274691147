*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

*::-webkit-scrollbar-track:hover {
  background-color: #FFFFFF;
}

*::-webkit-scrollbar-track:active {
  background-color: #FFFFFF;
}

*::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background-color: #5FC56D;
  border: 2px solid #FFFFFF;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #5FC56D;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #5FC56D;
}

div::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.previewCart {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 49;
    max-width: 560px;
    max-height: 800px;
    border-radius: 32px;
    background: #E3E3E3;
    border-radius: 32px;
    border: 3px solid #5FC56E;
    padding: 20px;
    overflow-x: auto;
    animation: showAnim .4s;
    animation-delay: .000001s;
    transition: .4s;
    ::-webkit-scrollbar {
      height: 14px;
      width: 14px;
    }

    &__header {
        color: #5FC56E;
        font-family: 'Archivo Black';
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        span { 
            position: absolute;
            left: 40px;
            font-size: 28px;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__body {
        overflow-y: scroll;
        max-height: 500px;

        &::-webkit-scrollbar {
          width: 10px;
          display: flex!important;
        }


        &__elem {
        background: #fff;
        border-radius: 20px;
        margin-bottom: 10px;
        width: 500px;
        }
        
    }

    &__footer {
        padding: 20px;
        padding-top: 40px;

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            

            &__title {
                color: #000;
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 500;
            }
        }
      &__continue {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        background: #5FC56D;
        padding: 10px 25px;
        width: fit-content;
        cursor: pointer;

        color: #FFF;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 400;
        margin: auto;
        &:active {
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
}

.previewProfile__right__paymentType__elem__row__info {
  margin-top: 20px;
  &__warn {
    color: #F22F2F;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; 
    margin-bottom: 60px;
  }
  &__row {
    font-family: Montserrat;
    font-weight: 600;
    label {
      color: #000;
      font-size: 24px;
    }
    &__price {
      color: #5FC56E;
      font-size: 26px;
      line-height: 23px;
    } 
    &__save {
      color: #A8A9A6;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.167px;  

      span {
        color: #F22F2F;
        font-family: Montserrat !important;
        font-size: 20px!important;
        font-weight: 500 !important;
        letter-spacing: -0.167px;
        margin-left: 20px;
      }
    }
  }    
}

@media screen and (max-width: 768px) {
  .previewCart {
    max-width: 375px;
    height: 667px;
    box-sizing: border-box;
    padding: 10px;
    &__header {
      width: 354px;
      justify-content: center;
      font-size: 18px;
      img {
        margin-left: 60px;
        width: 30px;
      }
      span {
        margin-right: 120px;
        position: relative;
        font-size: 18px;
      }
    }
    &__body {
      max-height: 410px;
      overflow-y: scroll;
      &__elem {
        width: 354px;
      }
    }
    &__footer {
      padding: 10px;
      padding-top: 30px;
      &__row {

        &__title {
          font-size: 16px;
        }
      }
      &__continue {
        font-size: 18px !important;
        margin-top: 50px;
      }
    }
  }
  .previewProfile__right__paymentType__elem__row {
    margin-bottom: 15px !important;
    &__check {
      & .MuiSvgIcon-root {
        font-size: 30px!important;
      }
    }
    &__info {
      &__row {
        label {
          font-size: 16px!important;
        }
        &__price {
          font-size: 18px!important;
        }
        &__save {
          font-size: 14px!important;
          span {
            font-size: 14px!important;
          }
        }
        &__sale {
          font-size: 14px!important;
        }
      }
    }
    &__info__warn {
      font-size: 20px!important;
      margin-bottom: 30px;
    }
  }
}