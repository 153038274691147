.OrderItemMobile {
    padding: 20px;
    background: #fff;
    border-radius: 32px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &__header {
        width: 100%;
        display: flex;
        img {
            width: 100px;
            height: 100px;
            margin-right: 20px;
        }
        &__info {
            width: 100%;
            display: flex;
            flex-direction: column;

            &__status {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin: 0;
                    padding: 0;

                    color: black;
                    font-size: 32px;
                    font-family: Montserrat;
                    font-weight: 600;
                    text-decoration: underline;
                }
                div {
                    color: #767676;
                    font-size: 32px;
                    font-family: Montserrat;
                    font-weight: 400;

                    span {
                        color: black;
                    }
                }
            }
            &__id {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                color: #767676;
                font-size: 32px;
                font-family: Montserrat;
                font-weight: 400;

                span {
                    color: black;
                }
            }
        }
    }
    &__body {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: fit-content;
            height: fit-content;
            border-radius: 32px;
            background: #D8D8D8;

            color: #4C4C4C;
            font-size: 32px;
            font-family: Montserrat;
            font-weight: 400;
            text-decoration: none !important;
        }

        span {
            color: #5FC56E;
            font-size: 42px;
            font-family: Montserrat;
            font-weight: 500;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 768px) {
    .OrderItemMobile {
        width: 335px;
        height: 90px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        margin: auto;
        margin-bottom: 10px;

        &__header {
            img {
                width: 40px;
                height: 40px;
                border-radius: 10px
            }
            &__info {
                &__status {
                    p {
                        font-size: 12px;
                    }
                    div {
                        font-size: 12px;
                    }
                }
                &__id {
                    margin-top: 10px;
                    font-size: 12px;
                }
            }
        }
        &__body {
            margin-top: 7px;
            &__btn {
                font-size: 12px;
                padding: 5px;
                img {
                    height: 15px;
                }
            }
            span {
                font-size: 18px;
            }
        }
    }
}