.ProfileHome {
  &__container {
      background: #E1E1E0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100vw;
    //   margin-left: -20px;
      min-height: 100vh;
    
      &__section {
        display: flex;
        width:80%;
        justify-content: center;
        margin: auto;
        margin-top: 100px;
  
        &__item {
            
            &__left {
                width: fit-content;
                margin-right: 20px;
            }
            &__right {
                width: fit-content;
            }
        }
    }
    }
}

@media screen and (max-width: 992px) {
  .ProfileHome__container {
      display: none;
  }
}

@media screen and (max-width: 1600px) {
  .ProfileHome {
      &__container {
        &__section {
          width: 90%;
      }  
      }
  }
}

@media screen and (max-width: 1400px) {
  .ProfileHome__container {
     display: none;
  }
}


//MainSec
.Profile__mainPage__section__mainSection {
  color: #4C4C4C;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  z-index: 1;
  margin-bottom: 30px;

  &__item__profile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 20px;
      background: #FFF;
      width: fit-content;
      padding: 20px 30px;
      margin-bottom: 80px;
      box-shadow: 8px 7px 16.6px rgba(0, 0, 0, 0.15);
      
      &__background {
          display: flex;

          &__img {
              color: rgba(0, 0, 0, 0.50);
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: -0.167px;
              text-decoration-line: underline;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-right: 30px;
      
              span {
                  margin-top: 20px;
                  img {
                      z-index: 2;
                      border: none;
                      width: 60px;
                      height: 60px;
                  }
              }
              img {
                  width: 200px;
                  height: 200px;
                  border-radius: 50%;
                  border: 3px solid #5FC56D;
                  object-fit: cover;
              }
          }
          
          &__name {
              margin-top: 5px;
              color: #000;
              font-size: 24px;
              font-weight: 400;
              letter-spacing: -0.167px;  
          }
          &__username {
              margin-top: 25px;
              color: #000;
              font-size: 20px;
              font-weight: 300;
              letter-spacing: -0.167px;
      
              span {
                  font-weight: 400;
              }
          }
          &__ambassador {
              margin-top: 25px;
              border-radius: 100px;
              background: #5FC56E;
              display: flex;
              width: fit-content;
              padding: 10px;
              align-items: center;
              justify-content: center;
              color: #FFF;
              font-size: 20px;
              font-weight: 300;
              letter-spacing: -0.167px;
          }
          &__id {
              margin-top: 25px;
              color: #000;
              font-size: 20px;
              font-weight: 300;
              letter-spacing: -0.167px;
              span {
                  font-weight: 400;
              }
          }
          &__info {
              // margin-right: 30px;
          }
          &__bonus {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              margin-left: -60px;
              margin-bottom: 8px;
      
              img {
                  margin-right: 10px;
                  width: 30px;
                  height: 30px;
              }
          }
          &__letters {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
      
              font-family: Montserrat;
              font-size: 24px;
              img {
                  margin-top: -8px;
              }
              div {
                  margin-top: -6px;
              }
          }   
      }
      
      &__count {
          font-family: Montserrat;
          font-size: 60px;
          font-weight: 500;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px auto;
      }

      &__wrapper{
          display: flex;
          align-items: center;

          img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin-right: 10px;
          }
          &__info {
              
          }
          &__name {
              margin-bottom: 5px;
          }
          &__stage {
              color: #FFF;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1.4px;
              border-radius: 7px;
              background: #5FC56E;
              padding: auto;
              width: 110px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 5px;
          }
          &__id {
              color: #4C4C4C;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 1.4px;
          }
      }
          
      &__editBtn {
          position: relative;
          right: 0;
          width: 140px;
          height: 40px;
          border-radius: 7px;
          border: 1px solid #9D9D9D;
          background: #FFF;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #3D3D3D !important;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1.8px;
        
          &::placeholder{
            color: #C2C2C2;
          }
        
          &:focus{
              outline: none;
              border: 1px solid black;
          } 
      }
  }


  &__item__profileData {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;
      margin-bottom: 20px;

      &__element {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          img {
              margin-right: 13px;
              width: 30px;
              height: 30px;
          }
          &__data {
              display: flex;
              flex-direction: column;

              &__title {
                  color: #4C4C4C;
                  font-size: 18px;
                  font-weight: 500;
                  letter-spacing: 1.8px;
              }
              &__subtitle {
                  color: #9D9D9D;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 1.4px;
              }
          }
      }
  }

  &__item__profileRef {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;

      &__info {
          display: flex;
          align-items: center;
          margin-bottom: 17px;

          img {
              width: 30px;
              height: 26px;
              margin-right: 13px;
          }
          span {
              color: #4C4C4C;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 1.8px;
          }
      }
      &__input {
          display: flex;
          align-items: center;

          input {
              width: 372px;
              height: 40px;
              border-radius: 10px;
              border: 1px solid #9D9D9D;
              background: #FFF;
              outline: none;
              padding: 0 13px;
              margin-right: 10px;

              &::placeholder {
                  color: #969696 !important;
                  font-family: Plus Jakarta Sans;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 1.6px;
              }
          }
          &__copyBtn {
              width: 100px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background: #5FC56E;
              cursor: pointer;
              transition: .4s;

              img {
                  width: 22px;
                  height: 22px;
                  filter: brightness(10);
                  margin-right: 5px;
              }
              span {
                  color: #FFF;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-weight: 400;
              }

              &:hover {
                  background: #e8e8e8;

                  img {
                      filter: none;
                  }
                  span {
                      color: #5FC56E;
                  }
              }
              &:active {
                  background: #5FC56E;
              }
          }
      }
  }
  &__item__personalData {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;
      
      &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          color: #393939;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2.4px;
          margin-bottom: 18px;

          div > span {
              color: #4C4C4C;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 1.6px;
          }
      }
      
      &__form {
          margin-top: -13px;
          &__element {
              margin-top: 28px;
              display: flex;
              flex-direction: column;
              
              .react-international-phone-input-container {
                  height: 44px !important;
              }
              .react-international-phone-country-selector {
                  height: 44px !important;
                  button {
                      height: 44px;
                      border-radius: 10px 0 0 10px !important;
                      border: 1px solid #9D9D9D !important;    
                  }
              }
              .react-international-phone-input {
                  height: 44px !important;
                  border-radius: 0 10px 10px 0 !important;
                  border: 1px solid #9D9D9D !important;
              }
              label {
                  margin-bottom: 5px;
              }

              color: #9D9D9D;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 1.4px;

              input {
                  color: #969696;
                  font-family: Plus Jakarta Sans;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 1.6px;

                  border: 1px solid #9D9D9D;
                  width: 372px;
                  height: 40px;
                  border-radius: 10px;
                  padding-left: 13px;
              }
          }

          &__save {
              width: 100px;
              height: 40px;
              background-color: #5FC56E;
              border-radius: 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFF;
              transition: .4s;
              margin-top: 20px;

              &:hover {
                  background-color: #e8e8e8;
                  color: #5FC56E;
              }
              &:active {
                  background-color: #5FC56E;
                  color: #fff;
              }
          }
      }
  }
  &__item__orders {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;

      &__title {
          color: #393939;color: #393939;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2.4px;
      }
      &__buttons {
          display: flex;
          align-items: center;
          margin-top: 20px;

          &__btn {
              padding: 11px;
              border-radius: 10px;
              background: #e8e8e8;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
              transition: .3s;

              color: #4C4C4C;
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 1.6px;
          }
      }
      &__content {
          margin-top: 40px;    

          &__item {
              display: flex;
              justify-content: space-between;
              margin-top: 15px;

              &__left {
                  width: 70%;
                      
                  &__header {
                      display: flex;
                      flex-direction: row;

                      img {
                          width: 50px;
                          height: 50px;
                          margin-right: 5px;
                      }
                      &__orderData {
                          display: flex;
                          flex-direction: column;
                          color: #4C4C4C;
                          font-family: Montserrat;

                          &__number {
                              font-size: 12px;
                              font-weight: 400;
                              letter-spacing: 1.2px;
                          }
                          &__status {
                              font-size: 18px;
                              font-weight: 500;
                              letter-spacing: 1.8px;
                              padding: 2px 0;
                          }
                          &__item {
                              font-size: 12px;
                              font-weight: 400;
                              letter-spacing: 1.2px;
                          }
                      }
                  }
                  &__btnContainer {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 83%;
                      margin-top: 10px;

                      &__btn {
                          width: 164px;
                          height: 40px;
                          border-radius: 10px;
                          background: #E8E8E8;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          color: #4C4C4C;
                          font-family: Montserrat;
                          font-size: 16px;
                          font-weight: 400;
                          letter-spacing: 1.6px;
                      }
                  }
              }
              &__right {
                  color: #5FC56D;
                  font-family: Roboto;
                  font-size: 24px;
                  font-weight: 400;
              }
          }
          hr {
              margin-top: 16px;
              background-color: #9D9D9D;
          }
          &__current {

              span {

              }
              &__btn {
                  margin-top: 30px;
                  transition: .4s;
                  width: 164px;
                  height: 40px;
                  border-radius: 10px;
                  background: #5FC56D;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #fff;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 1.6px;

                  &:hover {
                      background-color: #E8E8E8;
                      color: #5FC56D;
                  }
                  &:active {
                      background-color: #5FC56D;
                      color: #FFF;
                  }
              }
          }
          &__history {
              span {
                  margin-bottom: 38px;
              }
          }
      }
  }
  &__item__ambassador {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;
      margin-bottom: 20px;

      span {
          margin-bottom: 30px;
          color: #393939;color: #393939;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2.4px;
      }
      &__btnContainer {
          display: flex;
          align-items: center;
          margin-top: 20px;

          &__btn {
              padding: 11px;
              border-radius: 10px;
              background: #e8e8e8;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 20px;
              transition: .3s;

              color: #4C4C4C;
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 1.6px;
          }
      }
      &__description {
         margin-top: 30px;
         font-family: Montserrat;
         color: #4C4C4C;

         
         span {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 1.8px;
         }
         p {
          color: #9D9D9D;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1.4px;
         }
         .Profile__mainPage__section__mainSection__item__profileRef__info {
              display: flex;
              align-items: flex-start;
              margin-top: 45px;
              height: 13px;

              span {
                  font-size: 18px;
                  font-weight: 500;
                  letter-spacing: 1.8px;
              }
              
         }
         .Profile__mainPage__section__mainSection__item__profileRef__input{
          margin-top: 20px;
          &__copyBtn {


              span {
                  display: flex;
                  color: #FFF;
                  font-size: 16px;
                  font-weight: 400;
                  margin-top: 30px;
              }
          }
      }}
      &__statistics {
          display: flex;
          justify-content: space-between;
          width: 654px;
          padding: 24px 0;
          margin-bottom: 20px;
          color: #4C4C4C;
          font-family: Montserrat;

          &__item {
              width: 40%;
              border-radius: 10px;
              background-color: #FFF;
              padding: 22px;

              &__header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  
                  div {
                      display: flex;
                      align-items: center;
                      img {
                          width: 30px;
                          height: 26px;
                          margin-right: 10px;
                      }
                      span {
                          color: #4C4C4C;
                          font-size: 18px;
                          font-weight: 500;
                          letter-spacing: 1.8px;
                      }
                  }
                  span {
                      color: #5FC56E;
                      font-size: 16px;
                      font-weight: 500;
                      letter-spacing: 1.6px;
                  }
              }
              &__content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 30px;

                  &__title {
                      color: #4C4C4C;
                      font-size: 18px;
                      font-weight: 500;
                      letter-spacing: 1.8px;
                  }
                  span {
                      color: #9D9D9D;color: #5FC56E;
                      font-size: 16px;
                      font-weight: 500;
                      letter-spacing: 1.6px;
                  }
              }
              &__space {
                  height: 260px;
              }
          }
      }
      
  }
  &__item__language {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      width: 590px;
      padding: 24px 32px;
          

      span {
          margin-bottom: 30px;
          color: #393939;color: #393939;
          font-family: Roboto;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2.4px;
      }
  }
}
.Profile__mainPage__section__mainSection__item__personalData__form__phoneInput {
  width: 100% !important;
}


.Profile__mainPage__section__mainSection__item__profile__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 40px;
  label {
      margin-top: 40px;
      color: #000;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: -0.167px;
  }
  input {
      margin-top: 12px;
      padding: 0 10px;
      outline: none;
      width: 576px;
      height: 72px;
      border-radius: 20px;
      border: 2px solid #5FC56D;
      background: rgba(217, 217, 217, 0.50);
  }
}
.Profile__mainPage__section__mainSection__item__profile__save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 40px;

  &__btn {
      border-radius: 32px;
      background: #5FC56E;
      color: #FFF;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: -0.167px;
      padding: 20px 50px;

      &:hover {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
  }
}
.Profile__mainPage__section__mainSection__item__ordersSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__header {
      display: flex;
      justify-content: center;
      align-items: center;

      &__item {
          padding: 10px;
          border-radius: 50px;
          margin-right: 20px;
      }
  }
  &__content {
      margin-top: 40px;
      &__item {
          margin-top: 5px;
          background-color: #fff;
          border-bottom: 1px solid #000;
          border-radius: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px;
          width: 100%;
          flex-wrap: wrap;
          

          &__status {
              width: 80px;
              height: 80px;
              // margin-right: 20px;
          }
          &__info {
              display: flex;
              flex-direction: column;
              

              &__row {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  &__name {
                      color: #000;
                      font-family: Montserrat;
                      font-size: 24px;
                      font-weight: 600;
                      text-decoration-line: underline;
                  }

                  &__number {
                      color: #767676;
                      font-family: Montserrat;
                      font-size: 24px;
                      font-weight: 400;
                      width: fit-content;
                      span {
                          color: #000;
                      }
                  }
                  &__btn {
                      border-radius: 18px;
                      background: #D8D8D8;
                      padding: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 240px;
                      // margin-right: 44px;
                  }
                  &__price {
                      color: #5FC56E;
                      font-family: Montserrat;
                      font-size: 32px;
                      font-weight: 500;
                      text-decoration-line: underline;
                  }
              }
              &__column {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  height: 90px;
                  width: fit-content;
                  // margin-right: 20px;

                  &:last-child {
                      align-items: flex-end;
                  }
              }
          }
      }
  }
}

.Profile__mainPage__section__mainSection__item__profile__save__success {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #0000004d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}