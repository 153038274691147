.productsInfo {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 400px;
  overflow: hidden;


  &__content {
    max-height: 350px;

    &__title {
      display: flex;
      padding-right: 16px;
      border-bottom: 1px solid #CCCCCC;

      &__text {
        text-align: 'center';
        font-family: 'Archivo Black';
        font-size: 10px;
        margin: 0 auto;
        text-transform: uppercase;
        color: #A4A4A4;
        letter-spacing: 0.05em;
      }
    }

    &__items {
      overflow-y: scroll;
      height: 100%;

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Inter';


          &__conceptio {
            width: 70%;
            margin: 0 auto;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-family: 'Inter';
          }

          &__img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-left: 15px;

          }
        }
      }
    }
  }

}

@media screen and (max-width: 798px) {
  .productsInfo {
    max-height: 700px;
    &__content {
      max-height: 650px;

      &__items {
        max-height: 600px;
      }
    }

  }

}