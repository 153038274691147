.TableCard__div {
  display: flex;
  gap: 20px;
  width: fit-content;
  align-items: center;
  
  span {
    color: #838383 !important;
    width: fit-content;
    text-wrap: nowrap !important;
    font-size: 16px !important;
  }
}