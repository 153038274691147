.OrderSwiper {
  box-sizing: border-box;

  .swiper-wrapper {
    height: 550px;
  }

  &__itemsList {
    width: 100%;
    overflow-y: scroll;

    button {
      
    }
    &__editItems {
    overflow-y: scroll;
    height: 600px;
      
    }
  }

  .swiper-button-prev {
    color: #5FC56E;
    opacity: 0.2;
  }
  .swiper-button-next {
    color: #5FC56E;
    opacity: 0.2;
  }
  .swiper-slide {
    overflow-y: scroll;
    height: 100%;
  }
}

@media screen and (max-width: 720px) {
  .swiper-wrapper {
    height: 79vh !important;
  }
  .OrderSwiper__itemsList__editItems {
    height: 79vh !important;
  }
}