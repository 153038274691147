@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:wght@100..900&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.App {
  overflow-x: hidden;
}
.App__page {
  /* margin: auto; */
  height: fit-content;
}
.App__padding {
  /*margin: 0 20px;*/
}

.App__container {
  display: flex;
  justify-content: space-between;
  .App__container__left{
    /* width: 70%; */
  }
}

.App__container__column {
  display: flex;
  flex-direction: column;
}

body {
  background-color: #f5f4f2;
}

::-webkit-scrollbar {
  display: none;
}

.react-international-phone-input {
  font-family: Montserrat !important;
  font-size: 18px !important;
}