.PatternModal {
  position: fixed;
  z-index: 51;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.174);
  box-sizing: border-box;

  &__content {
    width: 340px;
    height: fit-content;
    max-height: 720px;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 32px;
    padding: 20px 0px;
    box-sizing: border-box;
    text-align: center;
    animation: showModal .5s;
    animation-delay: 0.000001s;
    overflow-y: scroll;

    &__close {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -10px;
      margin-right: 40px;
      img {
        width: 15px;
      }
    }
    &__children {
      width: 100%;
      position: relative;
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}

@media screen and (max-width: 720px) {
  .PatternModal__content {
    width: 100vw;
    height: 100vh;
    top: 0;
    position: absolute;
    max-height: 100%;
    border-radius: 0;
  }
}