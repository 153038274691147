.ContentCardCounter {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  
  &__units {
    overflow: hidden;
    width: 100%;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 32px;
    background: #5FC56E;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 3px 3px;
    
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: .4s;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 32px;
    }
  }
  &__quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 51px;
    overflow: hidden;
    &__counter {
      border-radius: 32px;
      background: #f5f4f2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &__change {
        margin-right: 15px;
        font-weight: 300;
        font-size: 60px;
        letter-spacing: 0.03em;
        color: #5fc56e;

        &:first-child {
          margin-left: 15px;
          margin-right: 0;
          margin-top: -10px;
        }
      }
      &__input {
        font-family: "Archivo Black", sans-serif;
        font-weight: 400;
        font-size: 24px;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          color: #5FC56E;
          background: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          text-align: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    &__addCart {
      margin-left: 5px;
      margin-top: 10px;
      box-sizing: border-box;
      border-radius: 32px;
      background: #fff;
      border: 3px solid #5fc56e;
      width: 100%;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      font-weight: 400;
      font-size: 24px;
      color: #5fc56e;
      img {
        // filter: brightness(0) invert(1);
        width: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ContentCardCounter {
    width: 100%;
    height: fit-content;
    
    
    &__units {
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
      
      font-weight: 400;
      font-size: 11px;
    }
    &__quantity {
      width: 100%;
      height: 30px;
      &__counter {
        border-radius: 32px;
        width: 174px;
        height: 100%;
        &__change {
          margin-right: 7px;
          font-weight: 300;
          font-size: 30px;
  
          &:first-child {
            margin-left: 7px;
            margin-right: 0;
            margin-top: -5px;
          }
        }
        &__input {
          font-weight: 400;
          font-size: 14px;
          width: 100%;
          height: 100%;
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
          input {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__addCart {
        border-radius: 32px;
        border: 1px solid #5fc56e;
        font-size: 16px;
        height: 30px;
        img {
          width: 15px;
        }
      }
    }
  }
  
}