.Profile__mainPage__section__mainSection__item__ordersSection {
    color: #4C4C4C;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;

    &__content {
        width: 100%;
        box-sizing: border-box;

        &__item {
            position: relative;
            width: 900px;
        }
    }
}