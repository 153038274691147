.CounterModal {
  z-index: 2;
  position: relative;
  min-width: 240px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;

  &__select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #5fc56e;
    border-radius: 32px;
    height: 50px;
    z-index: 2;

    &__controller {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 32px;
      width: fit-content;
      height: 100%;
      padding: 1px;
      margin-left: -1px;
      background: #f5f4f2;
      color: #5fc56e;

      &__button {
        overflow: hidden;
        margin-right: 10px;
        font-weight: 300;
        font-size: 42px;
        letter-spacing: 0.04em;
        &:first-child {
          font-size: 60px;
          margin-right: 0;
          margin-left: 10px;
          margin-top: -10px;
        }
      }
      &__quantity {
        width: 50px;
        font-family: "Archivo Black", sans-serif;
        font-weight: 400;
        font-size: 18px;
        input {
          width: 100%;
          height: 100%;
          align-content: center;
          text-align: center;
          outline: none;
          border: none;
          background: transparent;
          color: #5fc56e;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    &__unit {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 3 !important;
      
      button {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        img {
          margin: 0 5px;
          filter: brightness(10);
          width: 20px;
        }
      }
      &__options {
        display: flex;
        align-items: center;
        z-index: 4 !important;
        position: absolute;
        top: 50px;
        box-sizing: border-box;
        // left: 100%;
        min-width: 100px;
        background: #fff;
        border: 1px solid #5fc56e;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 3px 10px;

        &__item {
          width: 100%;
          padding: 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 24px;
          color: #000;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .CounterModal {
    min-width: 146px;

    &__select {
      border-radius: 32px;
      height: 25px;
      width: 100%;
  
      &__controller {
        border-radius: 32px;
        width: 80px;
        height: 100%;
        padding: 1px;
        margin-left: -1px;
  
        &__button {
          margin-right: 10px;
          font-weight: 300;
          font-size: 21px;
          &:first-child {
            font-size: 30px;
            margin-right: 0;
            margin-left: 10px;
            margin-top: -5px;
          }
        }
        &__quantity {
          font-size: 12px;
          font-weight: 400;
        }
      }
      &__unit {
        flex: 1;
        
        button {
          font-weight: 400;
          font-size: 12px;
          img {
            margin: 0;
            margin-left: 5px;
            width: 10px;
          }
        }
        &__options {
          right: 1px;
          min-width: 100px;
          border-radius: 15px;
          padding: 2px 5px;
          top: 25px;
  
          &__item {
            width: 100%;
            padding: 5px 0;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  } 
}