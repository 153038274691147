.cartModal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.115);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  width: 100%;
  max-height: 667px;
  background: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #5fc56e;
  flex: 3;

  &__header {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      font-weight: 500;
      font-size: 12px;
      color: #5fc56e;
    }
    &__proMod {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      color: #a4a4a4;
    }
  }
  &__body {
    margin-top: 20px;
    width: 100%;
    max-height: 600px;
    // object-fit: contain;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-flex-start;
    overflow-y: scroll;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      margin-bottom: 30px;

      font-weight: 400;
      font-size: 12px;
      color: #000;
      img {
        height: 40px;
        margin-right: 10px;
      }

      span {
        min-width: 40px;
        text-align: center;
        font-size: 12px !important;
      }
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & > span {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 16px !important;
          color: #000;
        }
        &__info {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: flex-start;
          &__sku {  
            color: rgba(0, 0, 0, 0.4);
            text-align: flex-start;
          }
        }
      }
    }
  }
  &__footer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__delivery {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        font-weight: 500;
        font-size: 16px;
        color: #5fc56e;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
    &__total {
      height: 39px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      font-weight: 700;
      font-size: 16px;
      color: #000;

      span {
        color: #5fc56d;
      }
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 107px;
        height: 39px;
        background: #5fc56d;
        border-radius: 32px;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 568px) {
  .cartModal {
    padding: 20px;
    width: 578px;
    max-height: 820px;
    &__header {
      &__back {
        font-size: 18px;
        img {
          width: 34px;
          height: 34px;
        }
      }
      &__proMod {
        font-size: 18px;
      }
    }
    &__body {
      &__item {
        margin-bottom: 40px;
        font-size: 18px;
        span {
          font-size: 18px !important;
          min-width: 60px;
        }
        &__row {
          img {
            height: 80px;
          }
          &__info {
            font-size: 18px;
            &__sku {
              font-size: 18px;
            }
          }
          span {
            font-size: 18px !important;
            width: 100% !important;
          }
        }
      }
    }
    &__footer {
      &__delivery {
        &__title {
          margin-top: 10px;
          font-size: 18px;
        }
      }
      &__total {
        justify-content: space-around;
        font-size: 18px;
        &__btn {
          font-size: 18px;
          height: 50px;
          width: 160px;
        }
      }
    }
  }
}