.ProfileInfo {
  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: #E1E1E0;
    
      &__section {
        display: flex;
        width:80%;
        justify-content: center;
        margin: auto;
        margin-top: 100px;
  
        &__item {
            
            &__left {
                width: fit-content;
                margin-right: 20px;
            }
            &__right {
                width: fit-content;
            }
        }
    }
    }
}

@media screen and (max-width: 992px) {
  .ProfileInfo__container {
      display: none;
  }
}

@media screen and (max-width: 1600px) {
  .ProfileInfo {
      &__container {
        &__section {
          width: 90%;
      }  
      }
  }
}

@media screen and (max-width: 1400px) {
  .ProfileInfo__container {
     display: none;
  }
}

@media screen and (min-width: 1400px) {
  .DatosPageMobile {
    display: none;
  } 
}