.toTopBtn {
    position: fixed;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5fc56e;
    border-radius: 32px;
    top: 90%;
    right: 69px;
    z-index: 40;
    transition: 1s;
    cursor: pointer;
}