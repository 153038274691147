.Profile__mainPage__section__mainSection__item__profile {
    font-family: Montserrat;
}
.ProfileMobileDatos {
    &__back {
        img {
            width: 60px;
        }
    }
    &__section {
        margin-top: 20px;
        margin-bottom: 50px;
        .Profile__mainPage__section__mainSection__item__profile {
            margin: auto;
        } 
    }
}

@media screen and (max-width: 765px) {
    .Profile__mainPage__section__mainSection__item__profile {
       width: 335px; 
       padding: 10px;
       box-sizing: border-box;

        &__background {
            &__img {
                img {
                    width: 96px;
                    height: 96px;
                }
                span {
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 12px;
					font-family: Montserrat;
                    font-weight: 400;
                    text-decoration: underline;
                }
            }
            &__info {
                .ProfilePlate__name {
                    color: black;
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 400;
                }
                .ProfilePlate__username {
                    margin-top: 10px;
                        color: black;
                        font-size: 12px;
                        font-family: Montserrat;
                        font-weight: 300;
                    span {
                        color: black;
                        font-size: 12px;
                        font-family: Montserrat;
                        font-weight: 400;
                    }
                }
                .ProfilePlate__ambassador {
                    margin-top: 10px;
                    color: white;
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 500;
                }
                .ProfilePlate__id {
                    margin-top: 10px;
                    color: black;
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 300;
                    span {
                        color: black;
                        font-size: 12px;
                        font-family: Montserrat;
                        font-weight: 400;
                    }
                }
            }
        }
        .Profile__mainPage__section__mainSection__item__profile__form {
            margin: auto;
            label {
                margin-top: 20px;
                color: black;
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
            }
            input {
                width: 260px; 
                height: 40px;
                background: rgba(217, 217, 217, 0.50);
                border-radius: 20px;
                border: 1px #5FC56D solid;
                
                color: rgb(36, 36, 36);
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                padding: 5px;
                box-sizing: border-box;
            }
        }
    }
    .Profile__mainPage__section__mainSection__item__profile__save {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Profile__mainPage__section__mainSection__item__profile__save__btn {
        width: 130px;
        height: 40px;
        
        text-align: center;
        color: white;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media screen and (min-width: 1400px) {
    .ProfileMobileDatos {
        &__back {
            display: none;
        }
    }
}

.ProfileMobileDatos {
    background: #E1E1E0;
    padding: 10px 0;
}