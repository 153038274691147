.counter__container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .counter {
    font-family: Montserrat;;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;


    background: #f5f4f2;
    border: 0 solid #9d9d9d;
    border-radius: 10px;
    height: 32px;
    font-weight: 300;
    .counter__decrement, .counter__increment{
      font-weight: 400;
    }
    .counter__unit, .counter__quantity{
      max-height: 32px;
      input{
        text-align: center;
      }
    }
    .counter__info{
      height: 32px;
      max-height: 32px;
      align-items: center;
      display: flex;
      gap: 2px;
    }
  }
}

@media screen and (max-width: 768px) {
  .counter {
    margin-left: 5px;
    width: 80px !important;
    height: 20px !important;
    border-radius: 6px;
    font-size: 12px!important;
    *{
      font-size: 12px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .counter__quantity {
      width: 20px;
    }
    .select__counter__unit {
      width: 30px;
    }
    .counter__info {
      div > div > div {
        padding-right: 16px !important;
      }
    }
  }
}