.NavAlphaList { 
  &__list-item {
    // padding: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  &__scroll__menu {
    position: fixed;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.467);
    border-radius: 8px;
    padding: 3px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin: auto 0;
    top: 50px;
    transition: all 0.5s;

    div {
      position: sticky;
      margin-bottom: 5px;
      opacity: 0.5;
      font-size: 24px;
    }
  }
  &__countainer {
    display: flex;
    width: 100vw;
    position: relative;
    justify-content: flex-start;
    align-items: center;

    
    &__listItems {
      overflow-y: scroll;
      display: flex;
      width: 90%;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 992px) {
  .NavAlphaList {
    &__scroll__menu {
      right: 0px;
    }
  }
}