.SearchListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px 10px;
  gap: 5px;

  &__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    gap: 5px;
    width: 100%;
    
    
    &__img {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.14em;
      color: #252525;
      text-align: start;
    }
  }
  &__dataParams {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.14em;
    text-align: center;
    color: #000;
    gap: 5px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 90%;
    border-bottom: 1px solid #E1E1E1;

    &__params {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.14em;
      text-align: center;
      color: #000;
      gap: 5px;
      display: flex;
      flex-direction: column;

      input {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.12em;
        text-align: center;
        color: #000;
        outline: none; 

        border: 1px solid #252525;
        border-radius: 10px;
        width: 130px;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      button {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.12em;
        text-align: center;
        color: #fff;
        outline: none; 
        border: none;
        background: #5fc56d;
        border: 1px solid #5fc56d;
        border-radius: 10px;
        width: 136px;
        height: 34px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        div {
          font-size: 32px;
          overflow: hidden;
          position: absolute;
          right: 8px;
          font-weight: 400;
        }
      }
    }
  }
}