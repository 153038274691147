.searchBar {
  z-index: 3;
  position: relative;

  &__closeHandler {
    background: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
  
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30vw;
    height: 55px;
    padding: 12px 25px;
    box-sizing: border-box;
    background: #fff;
    border: 2px solid #5fc56e;
    border-radius: 100px;
    transition: 1s;
    overflow: hidden;
    z-index: 4;
    position: relative;

    input {
      outline: none;
      border: none;
      width: 100%;
      font-size: 18px;
      font-family: Montserrat, sans-serif;
    }
    &__showMore {
      border-radius: 32px;
      width: 40px;
      height: 22px;
      background: #5fc56d;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: -10px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  &__itemsContainer {
    position: absolute;
    margin-top: 10px;
    width: 30vw;
    max-height: 520px;
    display: flex;
    flex-direction: column;
    z-index: 4;

    &__droplist {
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      width: 30vw;
      max-height: 450px;
      background: #fff;
      border-radius: 16px;
      filter: drop-shadow(5px 5px 10px rgba(117, 117, 117, 0.271));
      &:first-child  {
        overflow: visible;
      }

      &__go {
        position: absolute;
        right: -80px;
        top: 15px;
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #5fc56d;
        border-radius: 32px;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E8E8E8;
        padding: 10px 25px;
        box-sizing: border-box;
        font-size: 20px;
        font-family: Montserrat, sans-serif;

        img {
          width: 25px;
          height: 25px;
          margin-right: 20px;
        }
        span {
          width: 100%;
        }
        &__addBtn {
          align-self: flex-end;
          color: #fff;
          background: #5fc56d;
          border-radius: 50px;
          min-width: 30px;
          min-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
        }
      }
    }
  }
  &__showMore {
    width: 30vw;
    height: 50px;
    padding: 10px 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    font-family: Montserrat, sans-serif;
    background: #5fc56e;
    border-radius: 16px;
    margin-top: 10px;
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.297));
  }
}


@media screen and (max-width: 992px) {
  .searchBar {
    &__input {
      width: 50vw;
      height: 35px;
      padding: 6px 15px;

      img {
        width: 15px;
        height: 15px;
      }
    }
    &__itemsContainer {
      width: 50vw;
      &__droplist {
        width: 50vw;
        &__item {
          font-size: 14px;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      
    }
    &__showMore {
      width: 50vw;
      font-size: 16px;
    }
  }
  
}