.staticPage {
  width: 95%;
  margin: 0 auto;

  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: 'linear-gradient(180deg, #fff 51.04%, #176318 100%)';

  &__header {
    font-family: 'Montserrat';

    &__title {
      font-family: 'Archivo Black';
      @media screen and (max-width: 768px) {
        font-size: 20px !important;
        text-align: center !important;
      }
    }
  }
  &__filterOrders {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    button {
      background: transparent;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #4c4c4c;
    }
    .active {
      background: #5FC56E;
      color: #fff;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    width: 200px;
    height: 30px;
    background: #fff;
    overflow: hidden;
    
    input {
      font-weight: 500;
      font-size: 10px;
      color: #4c4c4c;
      outline: none;
      border: none;
      width: 100%;
      height: 30px;
      padding: 0px 10px;
    }
    img {
      margin-right: 10px;
    }
  }
}


@media screen and (max-width: 768px) {
  .staticPage {
    &__filterOrders {
      flex-wrap: wrap;
      button {
        flex: 1;
        flex-basis: 25%;
      }
    }
    &__search {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}