.LockModal {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  height: 100%;
  width: 100%;

  input {
    height: 80px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 48px;
    color: #5fc56e;
    letter-spacing: 0.25em;
    font-weight: 700;
    text-align: center;
  }
  button {
    outline: none;
    border: none;
    width: 100%;
    height: 30px;
    background: #5fc56e;
    border-radius: 8px;
    margin-top: auto;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}