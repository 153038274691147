.PreviewMoreTabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
    img {
        width: 50px;
    }
}

@media screen and (max-width: 992px) {
    .PreviewMoreTabs {
        img {
        width: 25px;
        }
    }
}