.ItemOrderElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  gap: 10px;

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &__img {
      width: 100px;
      height: 100%;
      img {
        width: 100%;
      }
    }
    &__data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &__name {
        font-weight: 500;
        font-size: 20px;
        color: #4c4c4c;
      }
      &__price {
        font-weight: 500;
        font-size: 20px;
        color: #4c4c4c;
        span {
          font-weight: 400;
        }
      }
    }
  }
  &__stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &__quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 23px;
      padding: 10px 20px;
      height: 75px;
      background: #b662aa;

      font-weight: 700;
      font-size: 40px;
      color: #fff;
    }
    &__totalPrice {
      font-weight: 700;
      font-size: 40px; 
      line-height: 122%;
      color: #b662aa;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-weight: 500;
        font-size: 32px;
        line-height: 122%;
        text-decoration: line-through;
        color: #a9a9a9;
      }
    }
  }
}