.mainCatalog {
  font-family: Montserrat;
  position: fixed;
  top: -50%;
  left: 50%-31.7%;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  

  &__background {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 20;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
  }

  .catalogModal {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      top: 80%;
      padding: 20px 0;
      left: 0;
      width: 31.7%;
      height: 495px;
      background-color: #fff;
      border-radius: 8px 0 0 8px;
      z-index: 20;
                  
      .categoriesPage {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          &__category {
              padding: 10px 15px;
              color: #4C4C4C;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 1.6px;
          }
      }

      &__additionSection {
          overflow-y: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          flex-direction: row;
          position: absolute;
          left: 31.7%;
          top: 80%;
          width: 600px;
          height: 495px;
          padding: 20px;
          border-radius: 0 8px 8px 0;
          border-left: 1px gray solid;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          z-index: 21;

          &__item {
              display: flex;
              align-items: center;
              position: relative;
              padding: 5px 5px;
              margin-bottom: 10px;
              width: 50%;

              &:active {
                  color: #5FC56E;
              }

              &__rectengle {
                  content: '';
                  background-color: #5FC56E;
                  width: 10px;
                  height: 10px;
                  border-radius: 3px;
                  margin-right: 10px;
              }
          }
      }
  }
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F8F8F8;
  border: 10px solid #EBEBEB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #B6E1BA;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #8BC593;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #5FC56E;
}
