.FavoriteModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  

  &__content {
    position: relative;
    background: #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    border-radius: 32px;
    width: 990px;
    max-height: 650px;
    box-sizing: border-box;
    

    &__title {
      font-size: 32px;
      font-weight: 500px;
      position: absolute;
      width: 100%;
      text-align: center;
      span {
        position: absolute;
        right: 20px;
      }
    }
    *::-webkit-scrollbar {
      height: 10px !important;
      width: 10px !important;
      display: block !important;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 40px;
      width: 100%;
      height: 100%;  
      overflow: hidden;
      overflow-y: scroll;
      

      & > div {
        margin-top: 20px;
      }
    }
  }
}

