.TemplateItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
  padding: 5px 10px;
  

  &__img {
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    gap: 5px;
    height: 100%;
    width: 100%;

    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.14em;
      color: #252525;
      text-align: start;
    }
    &__params {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      gap: 0px;
      border-radius: 32px;
      padding: 0px 5px;
      width: 240px;
      height: 30px;
      border: 1px solid #5fc56e;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.17em;
      text-align: center;
      color: #5fc56e;
    }
  }
}