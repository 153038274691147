.OrderPageMobile {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        margin: auto;
    }
    &__body {
        width: 990px;
        margin: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .OrderPageMobile {
        &__header {
            width: 70%;
        }
        &__body { 
        } 
    }
}

@media screen and (max-width: 992px) {
    .OrderPageMobile {
        &__header {
            width: 80%;
        }
        &__body { 
            width: 760px;
        } 
    }
}

@media screen and (max-width: 768px) {
    .OrderPageMobile {
        &__header {
            width: 335px;
        }
        &__body { 
            width: 100%;
        } 
    }
    
}