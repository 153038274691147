.StoreTab {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E1E1E0;
  box-sizing: border-box;
  padding-top: 10px;
  border-top: 4px solid;
  border-bottom: 4px solid;
  border-image: linear-gradient(90deg, #8ee164 0%, #09e3aa 100%) 1;

  &__btns {
    display: flex;
    align-items: center;
    gap: 10px;
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      gap: 5px;
      img {
        margin: 0;
      }
    }
  }

  &__title {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #5fc56d;
    width: 90%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      gap: 10px;

      &__switch {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        
      }
      &__settings {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        
      }
    }
    &__body {
      background-image: url('https://i.imgur.com/7ixIgcD.png');
      width: 100%;
      height: 120vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-sizing: border-box;
      padding: 10% 10%;

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;  
        position: relative;
        top: 3%;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 30px;
      padding-bottom: 20px;
    }
  }
}


@media screen and (min-Width: 556px) {
  .tab__btn {
    height: 60px;
    border-radius: 32px;
    min-width: 204px;
    font-size: 24px;
  }
  .StoreTab {
    &__content {
      width: 668px;
      &__body {
        padding: 66px;
        height: 800px;
      }
      &__footer {
        margin-top: -20px;
      }
    }
  }
}