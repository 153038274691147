.FilterModal {
  font-family: "Montserrat", sans-serif;
  * {box-sizing: border-box;}
  
  &__background {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.300);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11 !important;
  }
  &__modal {
    z-index: 11 !important;
    position: fixed;
    padding: 15px;
    left: 0;
    bottom: 0;
    background: #fff;
    border-radius: 0 50px 0 0;
    width: 182px;
    height: 673px;
    display: flex;
    flex-direction: column;


    &__header {
      border-radius: 0 38px 0 0;
      width: 182px;
      height: 63px;
      background: linear-gradient(90deg, #9be15d 0%, #00e3ae 100%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 40px;
      margin-left: -15px;
      margin-top: -15px;

      font-weight: 500;
      font-size: 18px;
      color: #fff;

      img {
        width: 14px;
        height: 14px;
        margin-bottom: 5px;
      }
    }

    &__filter {
      margin-top: 20px;
      &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__text {
          width: 100%;
          text-align: flex-start;
        }
      }

      &__priceSlider {
        margin-top: 35px;
        margin-bottom:40px !important;
        width: 90% !important;
      }
      &__priceInput {
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        div>input {
          outline: none;
          border: none;
          width: 100%;
          height: 15px;

          font-weight: 500 !important;
          font-size: 12px !important;
          line-height: 176% !important;
          color: #5fc56e !important;
        }
        div {
          font-family: "Poppins", sans-serif;
          padding: 0 5px 0 5px;
          display: flex;
          flex-direction: column;
          .input {
            margin-top: -4px;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0;
            width: 100%;
            font-weight: 500 !important;
            font-size: 12px !important;
            line-height: 176% !important;
            color: #5fc56e !important;
          }
          span {
            font-weight: 300;
            font-size: 10px;
            line-height: 211%;
            color: rgba(0, 0, 0, 0.4);
            height: fit-content;
          }
        }
        &__left {
          width: 100%;
          height: 40px;
          border: 1px solid #5FC56E;
          border-radius: 7px 0 0 7px;
        }
        &__right {
          width: 100%;
          height: 40px;
          border: 1px solid #5FC56E;
          border-left: none;
          border-radius: 0 7px 7px 0;
        }
      }

      &__offer {
        display: flex;
        flex-direction: column;
        &__switch {
          margin-top: 15px;
          margin-left: -5px;
        }
      }
    }

    &__footer {
      margin-top: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
        border-radius: 32px;
        padding: 10px;
        width: 120px;
        height: 30px;
        background: #5fc56e;

        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.1em;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .FilterModal {
    &__modal {
      width: 300px;

      &__header {
        width: 300px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 920px) {
  .FilterModal {
    &__modal {
      width: 500px;
      height: 800px;

      &__header {
        width: 500px;
        height: 80px;

        font-size: 24px;

        img {
          width: 20px;
          height: 20px;
        }
      }
      &__filter {
        font-size: 20px;
        &__price {
          &Slider {
            width: 96% !important;
          }
        }
        &__priceInput {
          div {
            padding: 0 10px;
            span {
              font-size: 20px;
            }
            .input {
              margin-top: -10px;
              font-size: 24px !important;
            }
            input {
              font-size: 24px !important;
              height: fit-content;
            }
          }
          &__left {
            height: 80px;
          }
          &__right {
            height: 80px;
          }
        }
      }
      &__footer {
        margin-top: 200px;
        &__btn {
          height: 60px;
          font-size: 24px;
          width: 200px;
        }
      }
    }
  }
}