.DataGridTable {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  width: 100%;

  &__container {
    overflow-x: scroll !important;
    width: 96vw;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    transition: 1s ease-in-out;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 5px;
    width: 100%;

    &__column {
      display: table-cell;
      align-items: center;
      justify-content: center;
      align-content: center;
      text-align: center;
      vertical-align: middle;
      horizontal-align: center;
      

      .checkbox {
        width: 25px;
        height: 25px;
        border-radius: 8px;
      }
    } 


    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: fit-content;
      transition: 1s ease-in-out;
      border-bottom: 1px solid #e8e8e8;
      background: #CBDCCB;
      padding: 5px;
      width: 100%;

      &__column {
        display: table-cell;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        vertical-align: middle;
        horizontal-align: center;
        color: #286F29;
        font-weight: 500;
        font-size: 16px;
      } 

      &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
        width: 100%;
        height: fit-content;
        color: white;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}

.DataGridTableOrders {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

@media screen and (max-width: 1000px) {
  .DataGridTable {
    width: 1200px;
  }
}