.HeaderBtn {
    color: black;
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 400;
    padding: 20px;
    cursor: pointer;
    width: fit-content;
}

@media screen and (max-width: 768px) {
    .HeaderBtn {
        min-width: 130px;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 2px #5FC56E solid;

        color: black;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 400;
    }
    
}