.SearchPage {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    overflow-x: hidden;
    &>div {
        margin: auto;
    }
    margin-left: 0;
}

@media screen and (max-width: 370px) {
    .SearchPage {
        padding: 0 0px;
    }
}