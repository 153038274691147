.cartElem{
  width: 100%;
  height: 130px;
  margin: 0px 10px;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  padding: 10px 0px;
  .cartElem__img{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    img{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
    }
  }
  .cartElem__header__and__footer{
    
    max-height: 110px;
    .contentCard__body{
      .contentCard__footer{
        
      }
      &__body {
        display: flex;
        flex-direction: column;
        // justify-content: center;

        &__title {
          color: #4c4c4c;
          align-self: left;
          margin-bottom: 5px;

					font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.6px;
        }

        &__subtitle {
          display: flex;
          align-self: flex-flex-start;
          // margin-top: 5px;
          gap: 5px;

          .stock {
            color: #5fc56e;
            border: 1px solid #5fc56e;
          }

          .dias {
            color: #407bff;
            border: 1px solid #407bff;
          }

          .outOfStock {
            color: #ff725e;
            border: 1px solid #ff725e;
          }

          .TBC {
            color: #ffc727;
            border: 1px solid #ffc727;
          }

          &__info {
            border-radius: 10px;
            background-color: #f5f4f2;
            padding: 2px;

            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.4px;

            &__svg {
              margin-right: 5px;
            }
          }
        }

        &__img {
          height: 150px;
          width: 150px;

          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin: 0 auto;
        }
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;


        &__price {
          // margin-top: 10px!important;
          color: #4c4c4c;
          margin-right: 10px;

					font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 1.6px;

          span {
            color: #4c4c4c;
            font-family: Montserrat;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 1.2px;
          }
        }

        &__addBtn {
          width: 30px;
          height: 30px;

          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }
        }
      }

    }

  }

}

.closeElemBtn {
  position: relative;
}

@media screen and (max-width: 768px) {
  .cartElem {
    width: 334px !important;
    box-sizing: border-box;
    &__header__and__footer {
      .contentCard__footer {
        &__price {
          width: fit-content;
          margin-right: 10px;
        }
        .CounterModal {
          font-size: 16px!important;
          font-family: Montserrat!important;
          min-width: 120px;
          &__select {
            height: 30px;
            &__controller {
              width: fit-content;
              &__button {
                margin: 0 3px;
                &:first-child {
                  margin-top: -10px;
                }
              }
            }
            &__unit {
              button {
                img {
                  margin: 0 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}