.cartModal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.115);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  width: 350px;
  height: 667px;
  background: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #5fc56e;
  flex: 3;

  &__header {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      font-weight: 500;
      font-size: 12px;
      color: #5fc56e;
    }
    &__proMod {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      color: #a4a4a4;
    }
  }
  &__body {
    margin-top: 20px;
    width: 100%;
    // max-height: 100%;
    // object-fit: contain;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      margin-bottom: 30px;
      flex-direction: column;

      font-weight: 400;
      font-size: 14px;
      color: #000;
      img {
        height: 40px;
        margin-right: 10px;
      }

      span {
        min-width: 40px;
        text-align: center;
        font-size: 12px !important;
      }
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        span {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 16px !important;
          color: #000;
        }
        &__info {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: flex-start;
          &__sku {
            color: rgba(0, 0, 0, 0.4);
            text-align: flex-start;
          }
        }
      }
    }
  }
  &__footer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__total {
      height: 39px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      font-weight: 700;
      font-size: 16px;
      color: #000;

      span {
        color: #5fc56d;
      }
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 39px;
        background: #5fc56d;
        border-radius: 32px;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
