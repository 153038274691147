.SalePageElement {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: visible;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  color: #000;
  gap: 10px;
  border-bottom: 1px solid #aeaeae;

  &__itemData {
    gap: 5px;
    display: flex;
    flex-direction: column;
    &__content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      img {
        height: 60px;
      }
    }
    &__name {
      font-weight: 700;
      font-size: 15px;
      color: #737373;
      width: 100%;
    }
  }

  &__bar {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    gap: 5px;
    height: fit-content;
    margin-top: 25px;

    &__start {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-weight: 700;
      font-size: 15px;
      color: #c55fb6;
      margin-bottom: 36px;
    }
    &__progressbar {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      
      &__goal {
        width: 100%;
        height: fit-content;
        position: relative;
        display: flex;
        align-items: center;

        &__weight {
          width: 102px;
          height: fit-content;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: #c45fb5;
          text-wrap: nowrap;
          box-sizing: border-box;
          z-index: 3;
          gap: 5px;
          margin-left: -51px;
          margin-bottom: -25px;

          &__arrow {
            height: 9px;
          }
        }
      }
      
      &__slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        box-shadow: inset 0 4px 12px 0 rgba(0, 0, 0, 0.09);
        background: #d9d9d9;
        border-radius: 50px;
        height: 30px;
        margin-bottom: 20px;
        margin-top: 25px;
        // overflow: hidden;
  
        &__target {
          font-weight: 700;
          font-size: 15px;
          color: #fff;
          position: absolute;
          right: 5px;  
        }
        &__indicator {
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
          background: linear-gradient(90deg, #c55fb6 0%, #5fc56e 100%);
          border: 1px solid #fff;
          height: 100%;
          width: 30%;
          border-radius: 50px;
          z-index: 2;
          font-weight: 500;
          font-size: 15px;
          color: #fff;
          text-wrap: nowrap;
        }
      }
    }
    &__end {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      font-weight: 700;
      font-size: 15px;
      color: #5fc56e;
      margin-bottom: 36px;
    }
  }

  &__costInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &__text {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.21em;
        text-align: center;
        color: #b4b4b3;
      }
      &__price {
        font-weight: 700;
        text-align: end;
        color: #5fc46e;
        font-size: 34px;
        span {
          color: rgba(76, 76, 76, 0.3);
          font-size: 24px;
          text-decoration: line-through;
        }
      }
    }
    &__subContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      &__text {
        font-weight: 500;
        font-size: 14px;
        text-align: right;
        color: #909090;
      }
      &__price {
        font-weight: 500;
        font-size: 14px;
        text-align: right;
        color: #b4b4b3;
        display: flex;
      }
    }
  }
}


@media screen and (max-width: 920px) {
  .SalePageElement {
    flex-wrap: wrap;
    align-items: flex-start;
    &__bar {
      order: 3;
    }
  }
}

@media screen and (max-width: 420px) {
  .SalePageElement {
    column-gap: 0px;
    &__itemData {
      &__name {
        width: 145px;
      }
    }
  }
}