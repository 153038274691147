.HeaderMobile {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 49px;
    background: linear-gradient(90deg, #9BE15D 0%, #00E3AE 100%);
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;

    &__logo {
        background: #fff;
        border-radius: 50px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 2px 10px;

        span {
            margin-left: 3px;
            position: relative;
            color: #5FC56E;
            text-wrap: nowrap;
            font-size: 14px;
            font-family: 'Archivo Black';
            font-weight: 400;
        }
    }
}


@media screen and (min-width: 993px) and (max-width: 1400px) {
    .HeaderMobile {
        display: flex;
        height: 100px;

        &__logo {
            padding: 12px 30px;
            box-sizing: border-box;
            img {
                width: 30px;
            }
            span {
                font-size: 22px;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .HeaderMobile {
        display: flex;
    }
}

@media screen and (max-width: 380px) {
    .HeaderMobile {
        padding: 10px 10px;
    }
}