.LoginModal {
    width: 100vw;
    height: 100vh;
    z-index: 50;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    box-sizing: border-box;

    &__background {
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
    }

    &__backgroundModal {
        background-color: #fff;
        border-radius: 30px;
        position: fixed;
        padding: 40px;
        width: 410px;
        transition: 1s;
        margin: auto;
        top: 10%;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            color: #4C4C4C;
            font-size: 24px;
            font-weight: 600;
            line-height: 27.6px; 
            letter-spacing: 2.4px;
        }
    }
    &__form {
        margin-top: 14px;
        display: flex;
        flex-direction: column;

        .label {
            margin-top: 22px;
            color: #000;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: -0.167px;
            width: 360px;
        }
        &__phoneInput {
            margin-top: 15px;
            display: flex;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.167px;
            height: 60px !important;
            
            color: #4C4C4C !important;
            font-family: Plus Jakarta Sans !important;
            font-size: 14px !important;
            font-weight: 600 !important;
            letter-spacing: 1.4px !important;
            width: 400px !important;

            div > button {
                border: 1px solid #000 !important;
                border-radius: 32px !important;
                height: 60px;
                padding: 0 9px;
            }
            input {
                border: 1px solid #000 !important;
                border-radius: 0 32px 32px 0 !important;
                padding: 9px 0 9px 40px !important;
                height: 60px !important;
                margin-left: -30px !important;
                width: 380px !important;

                color: #000;
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: -0.167px;
            }
        }
        input {
            border-radius: 32px;
            background: #FFF;
            width: 370px;
            height: 58px;
            padding: 0 20px;
            border: 1px solid #000;
            outline: none;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        &__showpassword {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-decoration: underline;
            cursor: pointer;
            transition: .4s;
        }
        &__remember {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            
            div {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                label {
                    color: #4C4C4C;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.1px;
                    letter-spacing: 1.4px;
                }
            }
            a {
                color: #5FC56D;
                font-size: 14px;
                font-weight: 600;
                line-height: 16.1px;
                letter-spacing: 1.4px;
                text-decoration: none;
            }
        }
    }
    &__button {
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__login {
            display: flex;
            align-items: center;
            justify-content: center; 
            height: 38px;
            margin-top: 36px;
            border-radius: 50px;
            border: 1px solid #5FC56D;
            background: #FFF;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: -0.167px;
            padding: 10px 20px;
            width: 108px;
            transition: .2s;
            cursor: pointer;
            &:active {
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            }

            &__active {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 38px;
                background: #5FC56D;
                border: 1px solid #5FC56D;
                color: #FFF;
                margin-top: 36px;
                border-radius: 50px;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: -0.167px;
                padding: 10px 20px;
                width: 108px;
                transition: .2s;
                cursor: pointer;
                &:active {
                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__text {
            margin-top: 24px;
            color: #4C4C4C;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.1px;
            letter-spacing: 1.4px;
        }
        &__icons {
            width: 200px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
        }
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
    background-color: white;
    color: #4C4C4C;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.4px;
}

@media screen and (max-width: 576px) {
    .LoginModal {
        &__backgroundModal {
            width: 90%;
            box-sizing: border-box;
            margin-left: -40px;
            .LoginModal__header {
                &__title {
                    color: #4C4C4C;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: 600;
                    line-height: 27.60px;
                }
            }
        }
        &__form {
            label {
                color: black;
                font-size: 12px !important;
                font-family: Montserrat;
                font-weight: 300;
            }
            &__phoneInput {
                margin-top: 10px;
                color: #000;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: -0.167px;
                height: 38px !important;
                
                color: #4C4C4C !important;
                font-family: Plus Jakarta Sans !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                letter-spacing: 1.4px !important;
                width: 90% !important;

                div > button {
                    border: 1px solid #000 !important;
                    border-radius: 32px !important;
                    height: 38px;
                    width: 50px;
                    padding: 0 9px;
                }
                input {
                    border: 1px solid #000 !important;
                    border-radius: 0 32px 32px 0 !important;
                    padding: 9px 0 9px 25px !important;
                    height: 38px !important;
                    margin-left: -16px !important;
                    width: 90% !important;

                    color: #000;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: -0.167px;
                }
            }
            &__input {
                height: 38px !important;
                width: 90% !important;
                box-sizing: border-box !important;
                margin-top: 10px !important;
            }
            &__showpassword {
                width: 90%;
                color: #5FC56D;
                font-size: 10px;
                font-family: Montserrat;
                font-weight: 600;
                line-height: 16.10px;
            }
            &__remember {
                width: 90%;
                div {
                    font-size: 10px !important;
                }
                a {
                    display: none;
                }
            }
        }
        &__button {
           width: 90%; 
           &__login {
               width: 100px;
               height: 40px;
               box-sizing: border-box;
               color: black;
               font-size: 12px;
               font-family: Montserrat;
               font-weight: 400;

               &__active {
                    width: 100px;
                    height: 40px;
                    box-sizing: border-box;
                    // color: black;
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 400;
               }
           }
        }
        &__footer {
            &__text {
                color: #4C4C4C;
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                line-height: 16.10px;
            }
            &__icons {
                img {
                    width: 30px;
                }
            }
        }
    }
}