.CategoryFilterModal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.115);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  width: 100%;
  max-height: 667px;
  background: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #5fc56e;
  flex: 3;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 600;
    font-size: 21px;
    color: #4c4c4c;
  }
  &__body {
    overflow-y: scroll;
    margin-top: 30px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__data {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        span {
          font-size: 12px;
        }
      }
      &__imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        img {
          height: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 568px) {
  .CategoryFilterModal {
    padding: 20px;
    width: 578px;
    max-height: 820px;
    &__header {
      font-weight: 600;
      font-size: 21px;
      color: #4c4c4c;
    }
    &__body {
      margin-top: 10px;
      gap: 10px;
      
      &__item {
        &__data {
          span {
            font-size: 16px;
          }
        }
        &__imgBox {
          border-radius: 10px;
          width: 80px;
          height: 80px;
          img {
            height: 100%;
          }
        }
      }
    }
  }
}