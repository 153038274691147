.ItemCounterModal {
  z-index: 1;
  position: relative;
  min-width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  height: 60px;

  &__select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #5fc56e;
    box-sizing: border-box;
    border-radius: 32px;
    height: 100%;

    &__controller {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 32px;
      width: 155px;
      height: 100%;
      padding: 1px;
      margin-left: -1px;
      background: #f5f4f2;
      color: #5fc56e;

      &__button {
        height: 100%; 
        overflow: hidden;
        padding-right: 10px;
        font-weight: 300;
        font-size: 42px;
        letter-spacing: 0.04em;
        display: flex;
        align-content: center;
        justify-content: center;
        svg {
          height: 100%;
          width: 100%;
        }
        &:first-child {
          padding-right: 0;
          padding-left: 10px;
        }
      }
      &__quantity {
        max-width: 70px;
        height: 100%;
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        font-weight: 400;
        font-size: 24px;
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        text-wrap: wrap;
        align-items: center;
        justify-content: center;
        justify-items: center;
        align-content: center;
        input {
          width: 100%;
          height: 35px;
          display: flex;
          text-align: center;
          text-wrap: wrap;
          align-items: center;
          justify-content: center;
          justify-items: center;
          align-content: center;
          text-align: center;
          outline: none;
          border: none;
          line-height: 100%;
          background: transparent;
          color: #5fc56e;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    &__unit {
      width: fit-content;
      display: flex;
      margin-left: 5px;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding-right: 10px;
      pre {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6px;
      }
      
      button {
        transition: .5s;
        color: #fff;
        height: 90%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 24px;
        border-bottom: 2px solid #5fc56e;
        
        img {
          margin: 0 10px;
          filter: brightness(10);
          width: 20px;
        }
      }
      &__options {
        position: absolute;
        top: 56px;
        min-width: 100px;
        background: #fff;
        border: 1px solid #5fc56e;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 3px 10px;

        &__item {
          width: 100%;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 24px;
          color: #000;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .ItemCounterModal {
    min-width: fit-content;
    height: 35px;

    &__select {
      border-radius: 32px;
      width: fit-content;
  
      &__controller {
        border-radius: 32px;
        width: 105px;
        height: 100%;
        padding: 1px;
        margin-left: -1px;
  
        &__button {
          margin-right: 5px;
          padding: 0;
          font-weight: 300;
          font-size: 20px;
          &:first-child {
            padding: 0;
            margin-right: 0;
            margin-left: 5px;
            margin-top: 0px;
          }
        }
        &__quantity {
          font-size: 12px;
          font-weight: 400;
          input {
            width: 45px;
            height: 9px;
            overflow: visible;
          }
        }
      }
      &__unit {
        
        button {
          font-weight: 400;
          font-size: 12px;
          img {
            margin: 0 10px;
            width: 10px;
          }
        }
        &__options {
          top: 28px;
          min-width: 50px;
          border-radius: 15px;
          padding: 2px 5px;
  
          &__item {
            width: 100%;
            padding: 5px 0;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  } 
}