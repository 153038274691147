.OrderElement {
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 32px;
  box-shadow: 6px 7px 17px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #252525;
  font-family: "Montserrat", sans-serif;
  padding: 15px 45px;
  font-size: 32px;
  transition: 1s;


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;

    &__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      font-weight: 400;
      font-size: 32px;
    }
    &__stats {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          h1 {
            font-family: "Archivo Black", sans-serif;
            font-weight: 400;
            font-size: 32px;
            margin-right: 10px;
          }
          p {
            font-weight: 500;
            text-decoration: line-through;
            color: #a9a9a9;
          }
          span {
            font-weight: 700;
            font-size: 40px;
            color: #5fc56e;
            margin: 0;
          }
        }
        span {
          color: #d9d9d9;
          margin: 0 10px;
        }
        .items {
          p {
            font-weight: 600;
            font-size: 32px;
            color: #252525;
            text-decoration: none;
            margin-right: 10px;
          }
          span {
            font-weight: 700;
            font-size: 40px;
            color: #5fc56e;
          }
        }
      }
      &__closeBtn {
        margin-right: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          transition: .5s;
        }
      }
    }
  }
  hr {
    background: #d9d9d9;
    width: 100%;
    border: none;
    height: 1px;
    margin-top: 10px;
  }
}