header{
  padding: 20px;
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header__left__side{
    display: flex;
    justify-content: space-between;
    width: 65%;

    .header__logo{
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      max-height: 80px;
      min-width: 330px;
      margin-right: 17px;
      span {
        margin-left: 12px;
        position: relative;
        color: #5FC56E;
        letter-spacing: 3px;
        text-wrap: nowrap;
        font-size: 32px;
        font-family: 'Archivo Black';
        font-weight: 400;
      }
    }
  }
  .header__right__side{
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: flex-end;
    width: fit-content;
    position: relative;
    bottom: 10px;
    margin-right: 34px;
    .header__phone__number{
      display: flex;
      grid-gap: 8px;
      align-items: center;
      width: fit-content;
      margin-left: 20px;
      .header__phone__number__text{
        display: flex;
        flex-direction: column;
        align-items: flex-flex-start;
        justify-content: center;
        color: #000;
        font-family: Montserrat;
        font-weight: 400;
        letter-spacing: 1.8px;
        text-wrap: nowrap;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  header {
    .header {
      &__logo {
        img {
          width: 40px;
        }
        span {
          font-size: 28px !important;
        }
      }
      &__phone__number {
        &__text {
          display: none !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  header {
    display: none;
  }
}


.header__search {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  background: #fff;
  border: 2px solid #5FC56E;
  border-radius: 32px;
  font-size: 20px;
  font-weight: 600;
  width: 400px;
}

@media screen and (max-width: 928px) {
  .header__search {
    width: 150px;
  }
}


@media screen and (max-width: 500px) {
  .header__search {
    // margin: 0 20px;
    margin: auto;
  }
}