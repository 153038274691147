.SalePageCounter {
  z-index: 1;
  position: relative;
  min-width: fit-content;
  height: fit-content;
  gap: 5px;
  display: flex;
  align-items: center;
  position: relative;

  &__decriment {
    border-radius: 100%;
    background: #f4f3f1;
    color: #c560b5;
    border: 2px solid #c560b5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 26px;
    position: absolute;
    right: 25px;
    top: -30px;
  }
  &__weight {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #c45fb5;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
  }
  &__incriment {
    border-radius: 100%;
    background: #f4f3f1;
    color: #5FC56E;
    border: 2px solid #5FC56E;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 26px;
    position: absolute;
    left: 25px;
    top: -30px;
  }

}