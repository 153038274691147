.ProfileSideBar__container {
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -1.5px;
    
    &__blockBtn {
        margin-bottom: 35px;

        &__itemBtn {
            margin-bottom: 40px;
            width: 308px;
            
            height: 40px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-radius: 7px;
            transition: .4s;

            img {
                margin-right: 15px;
            }

            &:hover {
                background-color: #e8e8e8;
            }
            .arrow {
                margin-left: 140px;
            }
        }
    }

    &__signOutBtn {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 7px;
        transition: .4s;
        
        img {
            margin-right: 15px;
        }
        
        &:hover {
            background-color: #e8e8e8;
        }
    }

    &__helpLinks {
        display: flex;
        align-items: center;
        margin-top: 15px;

        &__link {
           margin-right: 15px;
           transition: .4s;

           &:hover {
               text-decoration: underline;
               color: #5FC56D;
           }
        }
    }
}

@media screen and (max-width: 576px) {
    .ProfileSideBar__container {
        &__blockBtn {
            margin-bottom: 10px !important;
            &__itemBtn {
                width: 150px;
                height: 40px;
                box-sizing: border-box;
                margin-bottom: 10px;

                color: black;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 400;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                .arrow {
                    margin-left: 40px;
                    width: 10px;
                }
            }
        }
        &__signOutBtn {
            width: 150px;
            height: 40px;
            box-sizing: border-box;
            margin-bottom: 10px;

            color: black;
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}