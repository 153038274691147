.mainInfo {
  width: 65%;
  background-color: #fff;
  border-radius: 4px;
  height: max-content;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__title {
    width: 100%;
    height: 26px;
    background-color: #cbdccb;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__content {
    padding: 20px;
    display: flex;

    &__left {
      width: 25%;
      padding-right: 30px;

      &__avatar {
        position: relative;
        margin-bottom: 30px;
        width: 137px;

        &__text {
          font-family: 'Archivo Black';
          letter-spacing: 0.05em;
          text-transform: uppercase;
          padding: 7px;
          background-color: #407bff;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 15px;

          position: absolute;
          bottom: 0;

          transform: translate(0, 50%);
        }
      }

      &__undertext {
        font-family: 'Archivo Black';

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: #f0f0f0;
        padding: 7px;
      }
    }

    &__right {
      width: 75%;

      &__text {
        font-family: 'Archivo Black';

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &__title {
            color: #a4a4a4;
            font-family: 'Archivo Black';
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
          }

          &__image {
            cursor: pointer;
            border-radius: 3px;
            padding: 3px;
            border: 1px solid #fafafa;

            &:hover {
              background-color: #fafafa;
            }
          }

          &__value {
            font-size: 14px;
            font-family: 'Inter';
          }
        }
      }
    }
  }
}

@media screen and (max-width: 798px) {
  .mainInfo {
    width: 100%;

    &__content {
      padding: 5px;

      &__left {
        width: 100%;

        padding-right: 0%;

        &__avatar {
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
