.previewNavMobile {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 30px 0;
    display: none;
}

@media screen and (max-width: 1400px) {
    .previewNavMobile {
        display: flex;
        margin: 15px;
    }
    
}