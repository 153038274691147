.ItemCardModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

  &__content {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border-radius: 32px;
    width: 600px;
    min-height: 650px;
    box-sizing: border-box;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 135px;
      width: 100%;
      position: relative;

      .TBC {
        position: absolute;
        left: 0;
        z-index: 2;
        height: 25px;
        width: 55px;
        top: 0;
      }

      &__img {
        min-width: 200px;
        height: 180px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2 0px;
        margin-bottom: -60px;
        img {
          height: 100%;
        }
      }
      &__data {
        width: 100%;
        // height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.07em;
        color: #4c4c4c;
        align-self: flex-flex-start;
        margin-top: 10px;

        .stock {
          padding: 8px 20px !important;
          font-weight: 400;
          font-size: 18px;
          letter-spacing: 0.09em;
          color: #5fc56e;
        } 
        &__subtitle {
          display: flex;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            box-sizing: border-box;
          }
        }
      }
      &__btn {
        margin-left: 10px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
      }
    }
    &__body {
      width: 100%;

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 200px;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        margin-top: 8px;
        margin-bottom: 16px;
        &__price {
          font-weight: 500;
          font-size: 24px;
          letter-spacing: 0.07em;
          color: #4c4c4c;
          span {
            font-weight: 400;
            font-size: 20px;
          }
        }
      }      
      &__counter {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;


        &__price {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          font-weight: 600;
          font-size: 28px;
          color: #5fc56e;
          margin-top: 16px;
        }
      }
      &__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        &__text {
          margin: 4px 0;
          font-weight: 400;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.7);
          span {
            color: #000;
          }
        }
      }
    }
    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      &__addCartBtn {
        border-radius: 32px;
        padding: 10px;
        width: 260px;
        height: 58px;
        box-sizing: border-box;
        background: #5fc56e;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 24px;
        color: #fff;

        &:hover {
          background: #4c8e4c;
        }
      }
      &__continueBtn {
        border: 3px solid #5fc56e;
        border-radius: 32px;
        font-weight: 400;
        font-size: 24px;
        color: #5fc56e;
        padding: 10px;
        width: 260px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 5px;
          height: 25px;
          filter: brightness(.9);
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .ItemCardModal__content {
    padding: 20px;
    width: 350px;
    min-height: 500px;
    &__header {
      flex-direction: column;
      height: fit-content;
      width: 100%;
      position: relative;

      &__img {
        min-width: 100px;
        height: 200px;
        margin-right: 2 0px;
        margin-bottom: 0px;

        img {
          height: 100%;
        }
      }
      &__data {
        width: 100%;
        // height: 90%;/
        font-weight: 600;
        font-size: 12px;
        margin-top: 5px;

        .stock {
          padding: 4px 10px !important;
          font-weight: 400;
          font-size: 10px;
        } 
        &__subtitle {
          .contentCard__body__subtitle__info {
            height: 21.5px;
          }
        }
      }
      &__btn {
        margin-left: 5px;
        height: 100%;
        right: 0;
        position: absolute;
        img {
          width: 20px;
        }
      }
    }
    &__body {
      width: 100%;

      &__buttons {
        padding-left: 100px;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 8px;
        &__price {
          font-weight: 500;
          font-size: 12px;
          span {
            font-weight: 400;
            font-size: 10px;
          }
        }
        img {
          width: 15px;
        }
      }      
      &__counter {
        margin-top: 10px;
        margin-bottom: 8px;
        width: 100%;

        .gramos {
          margin-left: 5px;
          span {
            font-size: 12px !important;
          }
        }

        &__price {
          font-weight: 600;
          font-size: 14px;
          margin-top: 9px;
        }
      }
      &__info {
        width: 100%;
        &__text {
          margin: 2px 0;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    &__footer {

      &__addCartBtn {
        border-radius: 32px;
        padding: 5px;
        width: 125px;
        height: 29px;
        font-weight: 400;
        font-size: 12px;
      }
      &__continueBtn {
        transition: .4s;
        width: 125px;
        padding: 5px;
        width: 125px;
        height: 29px;
        font-weight: 400;
        font-size: 12px;
        border: 2px solid #5fc56e;
        img {
          height: 15px;
        }
      }
    }
  }
}